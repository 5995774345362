<template>
  <div>
    <div class="all-event-wrapper">
      <div class="event-top" v-if="showTeamOptionDiv">
        <div class="event-top-content">
          <div class="back-arrow" @click="closeDiv">
            <img src="../../../../assets/images/arrow-left.png" />
          </div>
          <div class="event-title">
            <div class="image-wrapper">
              <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar == ''"
                src="../../../../assets/images/main-team-avatar.png"
                alt=""
              />
              <img
                @load="onImageLoad"
                v-if="selectedRoomTeam.teamAvatar != ''"
                :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
                alt=""
              />
            </div>
            <div class="user-details">
              <p class="team-name" :id="selectedRoomTeam.id">
                {{ selectedRoomTeam.teamName }}
              </p>
              <span> Team Options </span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-items">
        <div
          class="member-list"
          data-toggle="modal"
          data-target="#editTeamImage"
          @click="groupDetails"
          v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/camera.png" alt="" />
            </div>
            <span>Change Team Image</span>
          </div>
        </div>
        <div
          class="member-list"
          data-toggle="modal"
          data-target="#editTeamModal"
          @click="groupDetails"
          v-if="currentIsAdmin == '1' && currentTeamIsActive == 1"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/edit.png" alt="" />
            </div>
            <span>Edit Team Details</span>
          </div>
        </div>

        <div
          style="display: none"
          data-toggle="modal"
          data-target="#editPhoneModal"
          ref="openModalupdatePhone"
          @click="dataReset()"
        >
          Edit Mobile
        </div>

        <div
          class="member-list"
          v-if="currentTeamIsActive == 1 && currentIsFan != '1'"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/notification.png" alt="" />
            </div>
            <span>SMS Notification</span>
          </div>
          <toggle-button
            class="m-0"
            v-model="smsNotification"
            :value="false"
            :sync="true"
            :width="34"
            @change="updateSMSNotification"
          />
        </div>
        <div
          class="member-list"
          v-if="currentTeamIsActive == 1 && currentIsFan != '1'"
        >
          <div class="member-detail">
            <div class="member-img">
              <img
                src="../../../../assets/images/push-notification.png"
                alt=""
              />
            </div>
            <span>Push Notification</span>
          </div>
          <toggle-button
            class="m-0"
            v-model="pushNotification"
            :value="false"
            :sync="true"
            :width="34"
            @change="updatePushNotification"
          />
        </div>
        <div
          class="member-list"
          v-if="
            currentTeamIsActive == 1 &&
              currentIsAdmin == '1' &&
              showLoader1 == 0 &&
              selectedRoomTeam.teamType == 'sports'
          "
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/team-options.png"  alt="" />
            </div>
            <span>Show On Base % as Average</span>
          </div>
          <toggle-button
            class="m-0"
            v-model="showOnBaseAvg"
            :value="false"
            :sync="true"
            :width="34"
            :disabled="teamData.team ? false : true"
            @change="updateShowOnBaseAvg()"
          />
        </div>
        <div
          class="member-list"
          v-if="
            currentTeamIsActive == 1 &&
              currentIsAdmin == '1' &&
              showLoader1 == 0 &&
              selectedRoomTeam.teamType == 'sports'
          "
         
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/team-options.png"  alt="" />
            </div>
            <span>Show average for top 5 players</span>
          </div>
          <toggle-button
            class="m-0"
            v-model="topPlayerStatus"
            :value="false"
            :sync="true"
            :width="34"
            :disabled="teamData.team ? false : true"
            @change="showTopPlayerAverage()"
          />
        </div>
        <div
          class="products-list-shim mt-3"
          v-if="showLoader1 && selectedRoomTeam.teamType == 'sports'"
        >
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="2" />
          </content-placeholders>
        </div>

        <div
          class="member-list member-list-red"
          @click="exitGroup()"
          v-if="currentTeamIsActive == 1"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/exit-team.png" alt="" />
            </div>
            <span>Exit Team</span>
          </div>
        </div>

        <div
          class="member-list member-list-red"
          @click="archiveGroup()"
          v-if="currentTeamIsActive == 1"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/archive-add.png" alt="" />
            </div>
            <span>Archive Team</span>
          </div>
        </div>

        <div
          class="member-list member-list-red"
          @click="unarchiveGroup()"
          v-if="currentTeamIsActive == 2"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/archive-minus.png" alt="" />
            </div>
            <span>Unarchive Team</span>
          </div>
        </div>

        <div
          class="member-list member-list-red"
          data-toggle="modal"
          data-target="#reportChat"
          data-backdrop="static"
          data-dismiss="modal"
          data-keyboard="false"
          v-if="currentTeamIsActive == 1"
        >
          <div class="member-detail">
            <div class="member-img">
              <img src="../../../../assets/images/dislike.png" alt="" />
            </div>
            <span>Report Team</span>
          </div>
        </div>
        <!-- <div class="team-invite-link team-invite-links"  @click="deleteTeam()" v-if="currentIsAdmin == '1'">
          <div class="invite-link red-background">
            <img src="../../../../assets/delete-red.png" alt="" />
          </div>
          <h6 class="red-color">Delete Team</h6>
        </div> -->
      </div>
    </div>

    <!-- Update Team Details Modal -->
    <div
      class="modal fade"
      id="editTeamModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editTeamModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="editTeamModalLabel">Update Team</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalupdateTeamDetails"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="team-name-type">
                <div class="form-group" style="margin-top: 5px">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    trim
                    autocomplete="off"
                    v-model="teamNamee"
                    v-on:keyup="errorNullTeam"
                  />
                  <span class="placeholder">Team Name</span>
                </div>
                <div class="form-group">
                  <small>Select Team Type</small>
                  <select
                    class="form-control form-control-lg mt-2"
                    v-model="teamTypes"
                    @change="errorNullTeam"
                  >
                    <option value="type" selected disabled>Type</option>
                    <option value="work">Work</option>
                    <option value="sports">Sports</option>
                    <option value="family">Family</option>
                  </select>
                </div>
                <div class="form-group" v-if="showSportType == 1">
                  <small>Select Sport Type</small>
                  <select
                    class="form-control form-control-lg mt-2"
                    id="sportTypes"
                    v-model="sportTypes"
                    @change="errorNullTeam"
                  >
                    <option
                      v-for="option in sportOptions"
                      :key="option.id"
                      :value="option.name"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-if="showSportType == 1">
                  <small>Select Age Group</small>
                  <select
                    class="form-control form-control-lg mt-2"
                    id="ageGroups"
                    v-model="ageGroups"
                    @change="errorNullTeam"
                  >
                    <option
                      v-for="option in ageOptions"
                      :key="option.id"
                      :value="option.name"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-if="showSportType == 1">
                  <small>Select Gender</small>
                  <select
                    class="form-control form-control-lg mt-2"
                    id="genders"
                    v-model="genders"
                    @change="errorNullTeam"
                  >
                    <option
                      v-for="option in genderOptions"
                      :key="option.id"
                      :value="option.name"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <!-- <div class="modal-footer">
            <div class="d-flex align-items-center ml-auto">
              <img
                class="loaderSign"
                style="margin-right: 12px; height: 28px"
                src="../../../../assets/loader.gif"
                v-if="this.showLoaderTeamDetails == 1"
              />
              <button
                v-if="this.isDisabledTeamDetails == false"
                type="button"
                class="btn btn-primary"
                block
                @click="updateTeamDetails()"
              >
                <span>Proceed</span>
              </button>
              <button
                v-else-if="this.isDisabledTeamDetails == true"
                type="button"
                class="btn btn-primary"
                :disabled="true"
              >
                <span>Proceed</span>
              </button>
            </div>
          </div> -->

          <div class="event-btns-wrapper px-3 pb-3 justify-content-end">
            <button
              v-if="this.isDisabledTeamDetails == false"
              type="button"
              class="btn btn-primary w-25"
              @click="updateTeamDetails()"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabledTeamDetails == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Update Team Image Modal -->
    <div
      class="modal fade"
      id="editTeamImage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              Update Team Image
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalTeamImage"
              @click="resetForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <input
                type="file"
                style="display: none"
                id="vue-file-upload-inputt"
                @change="addGroupAvatar"
                accept="image/png, image/gif, image/jpeg"
              />
              <div class="team-top-image">
                <div class="team-image" @click="openinput()">
                  <img
                    v-if="
                      selectedRoomTeam.teamAvatar == '' &&
                        this.groupAvatar.length == 0
                    "
                    src="../../../../assets/images/main-team-avatar.png"
                    alt=""
                    class="default-image"
                  />
                  <img
                    v-else-if="
                      selectedRoomTeam.teamAvatar != '' &&
                        this.groupAvatar.length == 0
                    "
                    :src="
                      this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar
                    "
                    alt=""
                  />
                  <clipper-basic
                    v-else-if="this.groupAvatar.length > 0"
                    :src="this.rawData"
                    preview="preview"
                    :border="2"
                    :outline="0"
                    :corner="false"
                    :ratio="1"
                    :wrap-ratio="1"
                    ref="clipper"
                  ></clipper-basic>
                  <div class="edit-icon-wrapper">
                    <img src="../../../../assets/images/edit.png" alt="" />
                  </div>
                </div>
                <div class="preview-image">
                  <div v-if="this.groupAvatar.length > 0">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div
                    v-else-if="this.groupAvatar.length == 0"
                    class="no-image-wrapper"
                  >
                    <div slot="placeholder">No image</div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="event-btns-wrapper px-3 pb-3 justify-content-end">
            <!-- <img
                class="loaderSign"
                style="margin-right: 12px; height: 28px"
                src="../../../../assets/loader.gif"
                v-if="this.showLoaderTeamImage == 1"
              />
              <button
                v-if="this.isDisabledTeamImage == false"
                type="button"
                class="btn btn-primary"
                block
                @click="updateTeamImage()"
              >
                <span>Proceed</span>
              </button>
              <button
                v-else-if="this.isDisabledTeamImage == true"
                type="button"
                class="btn btn-primary"
                :disabled="true"
              >
                <span>Proceed</span>
              </button> -->

            <button
              v-if="this.isDisabledTeamImage == false"
              type="button"
              class="btn btn-primary w-25"
              v-on:click="updateTeamImage(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabledTeamImage == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Update Phone Number Modal -->
    <div
      class="modal fade"
      id="editPhoneModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editPhoneModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="editPhoneModalLabel">
              Update Mobile Number
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalupdatePhone"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-0 pt-md-3">
            <p
              v-if="errorMessage != null"
              class="tag-line"
              style="color: red; margin-bottom: 10px"
            >
              {{ errorMessage }}
            </p>
            <form>
              <div class="d-md-flex align-items-center">
                <div class="phone-code team-phone-code">
                  <b-form-select v-model="code" :options="codeList">
                    <template #first> </template>
                  </b-form-select>
                </div>

                <div class="form-group ml-md-4 ml-0 mt-4 mt-md-0 phone-number">
                  <input
                    id="phone"
                    class="mb-0 form-control"
                    v-model.trim="$v.details.phone.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.phone),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Mobile Number</span>
                  <div v-if="!$v.details.phone.integer" class="invalid-tooltip">
                    Mobile number should be numbers.
                  </div>
                  <div
                    v-else-if="!$v.details.phone.minLength"
                    class="invalid-tooltip"
                  >
                    Minimum length should be 10.
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3">
            <button
              v-if="this.isDisabledPhoneNumber == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="updateMobile()"
              :disabled="isLoadingArrayPhone[0]"
            >
              <div v-if="isLoadingArrayPhone[0]" class="lds-dual-ring"></div>
              <span v-else>Procced</span>
            </button>
            <button
              v-else-if="this.isDisabledPhoneNumber == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="(true, isLoadingArrayPhone[0])"
            >
              <div v-if="isLoadingArrayPhone[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <Report :chatRoomId="roomId" :chatRoomType="chatRoomType" />
  </div>
</template>
<script>
import axios from "axios";
import { minLength, integer } from "vuelidate/lib/validators";
import * as firestoreService from "../../../../database/firestore";
import * as storageService from "../../../../database/storage";
import { mapGetters, mapActions } from "vuex";

import report from "../../../Report/report.vue";
import $ from "jquery";

import { clipperBasic, clipperPreview } from "vuejs-clipper";

export default {
  name: "TeamOptions",

  components: {
    Report: report,
    clipperBasic,
    clipperPreview,
  },
  data() {
    return {
      showTeamOptionDiv: false,
      imgLoader: 1,
      isLoadingArray: [],
      isLoadingArrayPhone: [],
      chatRoomType: "Team",
      errorMessage: null,
      showLoaderTeamDetails: 0,
      isDisabledTeamDetails: true,
      showLoaderPhoneNumber: 0,
      isDisabledPhoneNumber: true,
      showLoaderTeamImage: 0,
      isDisabledTeamImage: true,
      teamNamee: "",
      teamTypes: "",
      sportTypes: " ",
      ageGroups: " ",
      genders: " ",
      showSportType: 0,
      topPlayerStatus: false,
      sportOptions: [],
      ageOptions: [],
      genderOptions: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
        { name: "Coed", id: "Coed" },
      ],
      roomId: this.selectedRoomTeam.roomId,
      rawData: "",
      code: "+1",
      details: {
        phone: "",
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      groupAvatar: [],
      smsNotification: true,
      pushNotification: true,
      showOnBaseAvg: false,
      teamAvatar: "",
      items: [
        { id: "work", name: "Work" },
        { id: "sport", name: "Sport" },
        { id: "family", name: "Family" },
      ],
      teamData: [],
      showLoader1: 1,
    };
  },
  validations: {
    details: {
      phone: { integer, minLength: minLength(10) },
    },
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  mounted() {
    this.getTeamData();
    this.toggleDiv();
    const thisInstance = this;

    this.$root.$on("closeReportOption", function() {
      // thisInstance.closeDiv();
      thisInstance.closeDivAfterReport();
    });

    this.$store.commit("setErrors", {});
    const roles = this.selectedRoomTeam.userRole;
    const tempMembers = this.selectedRoomTeam.users;

    for (const role of roles) {
      for (const member of tempMembers) {
        // assign sms notification
        if (member.id == this.currentUserId && role.id == member.id) {
          if (role.notification == "off") {
            this.smsNotification = false;
          } else {
            this.smsNotification = true;
          }
          if (role.pushNotification == "off") {
            this.pushNotification = false;
          } else {
            this.pushNotification = true;
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["errors"]),
  },

  methods: {
    ...mapActions("auth", ["getUserData"]),
    onImageLoad() {
      this.imgLoader = 0;
    },
    toggleDiv() {
      this.currentTabUrl = localStorage.getItem("currentUrl");

      if (this.currentTabUrl == "/my-teams") {
        this.showTeamOptionDiv = false
      } else if (this.currentTabUrl == "/teams") {
        this.showTeamOptionDiv = true;
      }
    },
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    getSportType() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getSportType`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.sportOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    getAgeGroup() {
      this.errorNullTeam();
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    closeDiv: function() {
      // $(".chat-right-sidebar")
      //   .slideUp(200)
      //   .delay(200)
      //   .slideDown(200);

      this.$root.$emit("ChatSettings", 0);
      this.$root.$emit("fetchEvents");
    },
    closeDivAfterReport: function() {
      this.$root.$emit("ChatSettings", 0);
      this.$root.$emit("fetchEvents");
    },

    updateTeamDetails: function(index) {
      this.isDisabledTeamDetails = true;
      this.showLoaderTeamDetails = 1;
      this.$set(this.isLoadingArray, index, true);
      firestoreService.updateRoom(this.roomId, {
        teamType: this.teamTypes,
        teamName: this.teamNamee,
        sportType: this.sportTypes,
        ageGroup: this.ageGroups,
        gender: this.genders,
        // lastUpdated: new Date(),
      });
      this.postTeamDataUpdate(this.roomId);
      var elem = this.$refs.closeModalupdateTeamDetails;
      elem.click();
      this.showLoaderTeamDetails = 0;
      this.$set(this.isLoadingArray, index, false);
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    },

    TeamImageURItoBlob: function(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    updateTeamImage: function(index) {
      this.isDisabledTeamImage = true;
      this.showLoaderTeamImage = 1;
      this.$set(this.isLoadingArray, index, true);
      if (this.groupAvatar.length > 0) {
        const canvas = this.$refs.clipper.clip();
        var dataURL = canvas.toDataURL("image/jpeg", 0.95);
        var blob = this.TeamImageURItoBlob(dataURL);
        var formData = new FormData(document.forms[0]);
        this.groupAvatar.forEach((value, index) => {
          formData.append("images[" + index + "]", blob);
        });

        axios
          .post(
            process.env.VUE_APP_API_URL + "chat/storeGroupAvatar",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.teamAvatar = response.data.data;
            firestoreService.updateRoom(this.roomId, {
              teamAvatar: this.teamAvatar,
              // lastUpdated: new Date(),
            });
            this.postTeamDataUpdate(this.roomId);
            var elem = this.$refs.closeModalTeamImage;
            elem.click();
            this.showLoaderTeamImage = 0;
            this.$set(this.isLoadingArray, index, false);
            this.$root.$emit("gameTimeScheduledReload");
            this.$root.$emit("gameTimeTeammatesReload");
            this.$root.$emit("gameTimeStatisticsReload");
          });
      }
    },
    async postTeamDataUpdate(dataTeam) {
      var formData = new FormData();
      formData.append("team_id", dataTeam);
      if (this.teamNamee != "") {
        formData.append("team_name", this.teamNamee);
      }
      if (this.teamAvatar != "") {
        formData.append("team_avatar", this.teamAvatar);
      }
      if (this.sportTypes != "") {
        formData.append("sport_type", this.sportTypes);
      }
      if (this.ageGroups != "") {
        formData.append("age_group", this.ageGroups);
      }
      if (this.teamTypes != "") {
        formData.append("team_type", this.teamTypes);
      }
      if (this.genders != "") {
        formData.append("gender", this.genders);
      }

      await axios
        .post(process.env.VUE_APP_API_URL + "chat/updateTeamInfo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.showLoaderTeam = 0;
        });
    },
    openinput: function() {
      document.getElementById("vue-file-upload-inputt").click();
    },
    async updateShowOnBaseAvg() {
      const newValue = this.showOnBaseAvg ? "1" : "0";
      if (newValue) {
        this.$root.$emit("onBaseShowAvgValue", newValue);
      }
      const formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.id);
      formData.append("show_on_base_avg", newValue);
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "chat/updateShowOnBaseAvg",
          formData,
          {}
        );
      } catch (error) {
        console.error("Error updating team info:", error);
      }
    },
    async showTopPlayerAverage() {
      const newValue = this.topPlayerStatus ? "1" : "0";; // Convert boolean to string

      // Create FormData object and append necessary data
      const formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.id);
      formData.append("showTopPlayer", newValue); // Adjust the key based on backend requirement

      try {
        // Send data to the backend
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "chat/updateTopPlayerAvgStatus",
          formData
        );
        // console.log(response);
        this.$root.$emit("topPlayersData", response)

        // Handle response if needed
      } catch (error) {
        console.error("Error updating team info:", error);
      }
    },

    getTeamData() {
      this.showLoader1 = 1;
      const formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.id);
      axios
        .post(process.env.VUE_APP_API_URL + "chat/getTeamData", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamData = response.data.data;
              if (this.teamData.team && this.teamData.team.show_on_base_avg == 1) {
                this.showOnBaseAvg = true;
              }               
              if (this.teamData.team && this.teamData.team.show_top_player == 1) {
                this.topPlayerStatus = true;
              }
            
            this.showLoader1 = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader1 = 0;
          }
        });
    },
    addGroupAvatar: function(event) {
      const self = this;
      var fileInput = document.getElementById("vue-file-upload-inputt");
      var file_ = fileInput.value;
      var files = event.target.files;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert(
          "Please upload file having extensions .jpeg/.jpg/.png/.gif only."
        );
        fileInput.value = "";
        return false;
      } else {
        const file_ = files[0];
        this.groupAvatar.push({
          target: event.target.files[0],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData = e.target.result;
        };
        reader.readAsDataURL(file_);

        if (this.groupAvatar.length > 0) {
          this.isDisabledTeamImage = false;
        } else {
          this.isDisabledTeamImage = true;
        }
      }
    },
    errorNullTeam: function() {
      if (
        (this.teamNamee != this.selectedRoomTeam.teamName ||
          this.teamTypes != this.selectedRoomTeam.teamType ||
          this.sportTypes != this.selectedRoomTeam.sportType ||
          this.ageGroups != this.selectedRoomTeam.ageGroup ||
          this.genders != this.selectedRoomTeam.gender) &&
        this.teamNamee != ""
      ) {
        this.isDisabledTeamDetails = false;
      } else {
        this.isDisabledTeamDetails = true;
      }
      if (this.teamTypes == "sports") {
        this.showSportType = 1;
      } else {
        this.showSportType = 0;
      }
      if (
        this.showSportType == 1 &&
        this.teamNamee != "" &&
        this.sportTypes != "" &&
        this.ageGroups != "" &&
        this.genders != ""
      ) {
        this.isDisabledTeamDetails = false;
      } else if (this.showSportType == 0 && this.teamNamee != "") {
        this.isDisabledTeamDetails = false;
      } else {
        this.isDisabledTeamDetails = true;
      }
    },

    groupDetails: function() {
      this.getSportType();
      this.getAgeGroup();
      this.teamNamee = this.selectedRoomTeam.teamName;
      this.teamTypes = this.selectedRoomTeam.teamType;
      this.sportTypes = this.selectedRoomTeam.sportType;
      this.genders = this.selectedRoomTeam.gender;
      this.ageGroups = this.selectedRoomTeam.ageGroup;
      this.roomId = this.selectedRoomTeam.roomId;
      this.showLoaderTeamDetails = 0;
      this.isDisabledTeamDetails = true;
      this.showLoaderTeamImage = 0;
      this.isDisabledTeamImage = true;
      this.rawData = "";
      this.groupAvatar = [];
      this.teamAvatar = "";
      if (this.teamTypes == "sports") {
        this.showSportType = 1;
      } else {
        this.showSportType = 0;
      }
    },
    groupImage: function() {
      this.rawData = "";
      this.groupAvatar = [];
    },
    async exitGroup() {
      // check if you are the only admin.
      const teamSUserRole = this.selectedRoomTeam.userRole;
      var admincount = 0;
      for (const userRole of teamSUserRole) {
        if (userRole.id != this.currentUserId && userRole.admin == "1") {
          admincount++;
        }
      }
      if (admincount > 0) {
        this.$confirm(
          "About to exit the " +
            this.selectedRoomTeam.teamName +
            " team, Continue?",
          "Exit Team"
        ).then(() => {
          firestoreService.removeRoomUser(this.roomId, this.currentUserId);

          // Remove from User Role Array
          const teamSUserRole = this.selectedRoomTeam.userRole;
          var i = 0;
          for (const userRole of teamSUserRole) {
            if (userRole.id == this.currentUserId) {
              this.selectedRoomTeam.userRole.splice(i, 1);
            }
            i++;
          }
          firestoreService.updateRoom(this.roomId, {
            userRole: this.selectedRoomTeam.userRole,
          });

          // Remove from User Count Array
          const teamSUserCount = this.selectedRoomTeam.userCount;
          var s = 0;
          for (const userCount of teamSUserCount) {
            if (userCount.id == this.currentUserId) {
              this.selectedRoomTeam.userCount.splice(s, 1);
            }
            s++;
          }
          firestoreService.updateRoom(this.roomId, {
            userCount: this.selectedRoomTeam.userCount,
            // lastUpdated: new Date(),
          });

          this.$root.$emit("showTeamList");
          let window_width = window.innerWidth;
          if (window_width <= 991) {
            $(".vac-col-messages").show();
            $(".chat-right").hide();
          } else if (window_width >= 992) {
            $(".chat-right").show();
            $(".vac-col-messages").show();
          }
          this.$router.push({ name: "Teams" });
        });
      } else {
        this.$confirm(
          "You are the only admin of this team. You must set at least 1 member as admin of this team in order to exit",
          "Unable to Exit Team."
        ).then(() => {});
      }
    },

    async archiveGroup() {
      this.$confirm(
        "About to archive the " +
          this.selectedRoomTeam.teamName +
          " team, Continue?",
        "Archive Team"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == this.currentUserId) {
            this.selectedRoomTeam.userRole[i].status = 2;
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: this.selectedRoomTeam.userRole,
        });

        this.$root.$emit("showTeamList");
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");

        let window_width = window.innerWidth;
        if (window_width <= 991.98) {
          $(".vac-col-messages").show();
          $(".chat-right").hide();
        } else if (window_width >= 992) {
          $(".chat-right").show();
          $(".vac-col-messages").show();
        }
      });
    },

    async unarchiveGroup() {
      this.$confirm(
        "About to unarchive the " +
          this.selectedRoomTeam.teamName +
          " team, Continue?",
        "Unarchive Team"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == this.currentUserId) {
            this.selectedRoomTeam.userRole[i].status = 1;
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: this.selectedRoomTeam.userRole,
        });

        this.$root.$emit("showTeamArchiveList");
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
        let window_width = window.innerWidth;
        if (window_width <= 991.98) {
          $(".vac-col-messages").show();
          $(".chat-right").hide();
        } else if (window_width >= 992) {
          $(".chat-right").show();
          $(".vac-col-messages").show();
        }
      });
    },

    async updateSMSNotification() {
      if (this.smsNotification == true) {
        this.smsNotification = false;
        if (this.user.mob == "" || this.user.mob == null) {
          this.$confirm(
            "Do you want to add it now ?",
            "To receive sms notifications you must provide your mobile number."
          ).then(() => {
            var elem = this.$refs.openModalupdatePhone;
            elem.click();
          });
        } else {
          this.smsNotification = true;
          this.updateNotification();
        }
      } else {
        this.updateNotification();
      }
    },

    async updatePushNotification() {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      var i = 0;
      for (const userRole of teamSUserRole) {
        if (userRole.id == this.currentUserId) {
          if (this.pushNotification == false) {
            teamSUserRole[i].pushNotification = "off";
          } else {
            teamSUserRole[i].pushNotification = "on";
          }
        }
        i++;
      }
      firestoreService.updateRoom(this.roomId, {
        userRole: teamSUserRole,
      });
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    },

    async updateNotification() {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      var i = 0;
      var notify = "off";
      for (const userRole of teamSUserRole) {
        if (userRole.id == this.currentUserId) {
          if (this.smsNotification == false) {
            teamSUserRole[i].notification = "off";
          } else {
            teamSUserRole[i].notification = "on";
          }
        }
        i++;
      }
      if (this.smsNotification == true) {
        notify = "on";
      }
      firestoreService.updateRoom(this.roomId, {
        userRole: teamSUserRole,
      });
      var formData = new FormData();
      formData.append("group_id", this.roomId);
      formData.append("notify", notify);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "smsNotification/updateSMSNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getUserData();
        });
    },

    async deleteTeam() {
      this.$confirm(
        "About to delete the " +
          this.selectedRoomTeam.teamName +
          " team, Continue?",
        "Delete Team"
      ).then(() => {
        // Delete Messages
        firestoreService.getMessages(this.roomId).then(({ data }) => {
          data.forEach((message) => {
            firestoreService.deleteMessage(this.roomId, message.id);
            if (message.files) {
              message.files.forEach((file) => {
                storageService.deleteFile(this.currentUserId, message.id, file);
              });
            }
          });
        });

        // Delete Team
        firestoreService.deleteRoom(this.roomId);
        this.$root.$emit("showTeamList");
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");

        var formData = new FormData();
        formData.append("team_id", this.roomId);

        // Delete Event, game, Team from db.
        axios
          .post(process.env.VUE_APP_API_URL + "chat/deleteTeam", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {});
      });
    },

    // ------ Update Mobile -------------------------------------------
    errorNull: function() {
      this.errorMessage = null;
      if (
        this.details.phone == "" ||
        this.details.phone.length < 10 ||
        isNaN(this.details.phone) ||
        this.$v.$invalid
      ) {
        this.isDisabledPhoneNumber = true;
      } else {
        this.isDisabledPhoneNumber = false;
      }
    },
    dataReset: function() {
      this.errorMessage = null;
      this.details.phone = "";
      this.code = "+1";
      this.isDisabledPhoneNumber = true;
    },
    resetForm: function() {
      document.getElementById("vue-file-upload-inputt").value = "";
    },
    async updateMobile(index) {
      const data = this.details;
      data.code = this.code;
      this.$set(this.isLoadingArrayPhone, index, true);
      return axios
        .post(process.env.VUE_APP_API_URL + "user/updateMobile", data, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.smsNotification = true;
            var elem = this.$refs.closeModalupdatePhone;
            this.$set(this.isLoadingArrayPhone, index, false);
            elem.click();
            this.updateNotification();
          } else {
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArrayPhone, index, false);
          }
        });
    },
  },
};
</script>
<style scoped>
.label-type {
  font-size: 10px;
  margin-left: 20px;
  color: #6c757d;
}
.team-invite-links:hover {
  background-color: #fae0e1 !important;
  border-radius: 5px;
}
.team-options-wrapper {
  padding-top: 10px;
  position: relative;
}
.team-top {
  display: flex;
  align-items: center;
}
.team-top-image {
  display: flex;
  gap: 10px;
}
.team-top .back-arrow {
  margin-right: 15px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-top .back-arrow:hover {
  background-color: #e2f5fe;
  color: #ffffff;
  border-radius: 5px;
}

.team-member-actions {
  padding-top: 20px;
}

.team-member-actions h6 {
  color: #254c72;
  font-weight: 500;
  font-size: 15px;
  margin: 10px 0;
}
.team-member-actions .team-add-new,
.team-member-actions .team-invite-link {
  display: flex;
  align-items: center;
  margin-top: -6px;
  cursor: pointer;
  width: 100%;
}
.team-member-actions .add-new,
.team-member-actions .invite-link {
  background-color: #e5f2fe;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 0;
}

.team-member-actions .team-invite-link img {
  width: 20px;
}
.team-member-actions .team-add-new img {
  width: 20px;
}
.team-member-actions .team-add-new h6,
.team-member-actions .team-invite-link h6 {
  color: #254c72;
  font-weight: 500;
  font-size: 15px;
  margin: 10px 0;
}

.red-background {
  background-color: #fae0e1 !important;
}

.team-members-functions {
  color: #188ef5;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}

.edit-team:hover,
.team-add-new:hover {
  background-color: #e5f2fe;
  border-radius: 5px;
}

.team-image {
  width: 250px;
  height: 250px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}

.team-image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.team-image .default-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.edit-icon-wrapper {
  position: absolute;
  top: 82%;
  background-color: #e5f2fe;
  right: 4%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.delete-icon-wrapper {
  background-color: rgb(242, 253, 236);
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10%;
  right: 120px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.edit-icon-wrapper img,
.delete-icon-wrapper img {
  width: 20px;
  height: 20px;
}

.select-problem h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #111115;
  margin-bottom: 10px;
}
.select-problem p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(17, 17, 21, 0.4);
  margin-bottom: 20px;
}
.problem-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.problem-list li {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #111115;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#reportChat .close,
#ReportDescriptionModal .close {
  color: #188ef5 !important;
  font-weight: normal;
}

#reportChat .modal-header h5,
#ReportDescriptionModal .modal-header h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #254c72;
}

.report-problem label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #111115;
  margin-bottom: 20px;
}

.report-problem textarea {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #868585;
  border: 1px solid #868585;
  padding-left: 15px;
  padding-top: 10px;
  border-radius: 5px;
  resize: none;
}

.teamReportModal {
  overflow-y: auto;
  height: 555px;
}

.problem-list li:last-child {
  margin-bottom: 0;
}
</style>
