<template>
  <div>
    <!-- Update Uniform -->
    <div
      class="modal fade"
      id="addUniform_Modal"
      role="dialog"
      aria-labelledby="addUniform_Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="addUniform_ModalLabel">
             Player Info
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalUniform"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="uniform"
                    v-model="uniform"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                    maxlength="2"
                  />
                  <span class="placeholder">Uniform No#</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="bat_size"
                    v-model="bat_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"

                    required
                  />
                  <span class="placeholder">Bat Size</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="bat_details"
                    v-model="bat_details"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                  <span class="placeholder">Bat Load</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="hat_size"
                    v-model="hat_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                  <span class="placeholder">Hat Size</span>
                </div>
              </div>
              <div class="col-md-6 pr-md-0">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="shirt_size"
                    v-model="shirt_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                  <span class="placeholder">Shirt Size</span>
                </div>
              </div>
              <div class="col-md-6 pl-md-2">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control message bg-white"
                    id="pant_size"
                    v-model="pant_size"
                    @keyup="errorNull"
                    @input="handleInputChange()"
                    required
                  />
                  <span class="placeholder">Pant</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex align-items-center ml-auto">
              <img
                class="loaderSign"
                style="margin-right: 12px; height: 28px"
                src="../../../../assets/loader.gif"
                v-if="this.showLoader == 1"
              />
              <button
                v-if="this.isDisabled == false"
                type="button"
                class="btn btn-primary"
                block
                v-on:click="update()"
              >
                <span>Proceed</span>
              </button>
              <button
                v-else-if="this.isDisabled == true"
                type="button"
                class="btn btn-primary"
                :disabled="true"
              >
                <span>Proceed</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Uniform modal -->
  </div>
</template>
<script>
import * as firestoreService from "../../../../database/firestore";
export default {
  name: "AddUniformModal",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  data() {
    return {
      isDisabled: false,
      showLoader: 0,
      uniform: "",
      userChatId: "",
      bat_size: "",
      bat_details: "",
      hat_size: "",
      shirt_size: "",
      pant_size: "",
    };
  },

  methods: {
    // ---- Check Null ----------------------------------------------------------------------
    errorNull: function() {
      if (
        this.uniform == "" ||
        this.bat_size == "" ||
        this.bat_details == "" ||
        this.hat_size == "" ||
        this.shirt_size == "" ||
        this.pant_size == ""
      ) {
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false
      }
    },
    handleInputChange() {
    // Check if any of the input values have changed
    const isValueChanged =
      this.uniform !== this.initialUniform ||
      this.bat_size !== this.initialBatSize ||
      this.bat_details !== this.initialBatDetails ||
      this.hat_size !== this.initialHatSize ||
      this.shirt_size !== this.initialShirtSize ||
      this.pant_size !== this.initialPantSize;

    // Update the flag accordingly
    this.isDisabled = !isValueChanged;
  },

    // ---- Update ----------------------------------------------------------------------
    async update() {
      this.isDisabled = true;
      this.showLoader = 1;
      const teamSUserRole = this.selectedRoomTeam.userRole;
      var i = 0;
      for (const userRole of teamSUserRole) {
        if (userRole.id == this.userChatId) {
          teamSUserRole[i].uniform = this.uniform;
          teamSUserRole[i].bat_details = this.bat_details;
          teamSUserRole[i].bat_size = this.bat_size;
          teamSUserRole[i].hat_size = this.hat_size;
          teamSUserRole[i].shirt_size = this.shirt_size;
          teamSUserRole[i].pant_size = this.pant_size;
        }
        i++;
      }
      firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
        userRole: teamSUserRole,
        // lastUpdated: new Date(),
      });
      this.showLoader = 0;
      this.$alert("Player info has been updated successfully.");
      var elem = this.$refs.closeModalUniform;
      elem.click();
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    },
  },
  mounted() {
    this.$root.$on("fetchUniformData", (userChatId) => {
      this.isDisabled = true;
      this.showLoader = 0;
      this.userChatId = userChatId;

      const teamSUserRole = this.selectedRoomTeam.userRole;
      for (const userRole of teamSUserRole) {
        if (userRole.id == userChatId) {
          if (userRole.uniform != undefined) {
            this.uniform = userRole.uniform;
          } else {
            this.uniform = "";
          }
          if (userRole.bat_size != undefined) {
            this.bat_size = userRole.bat_size;
          } else {
            this.bat_size = "";
          }
          if (userRole.bat_details != undefined) {
            this.bat_details = userRole.bat_details;
          } else {
            this.bat_details = "";
          }
          if (userRole.hat_size != undefined) {
            this.hat_size = userRole.hat_size;
          } else {
            this.hat_size = "";
          }
          if (userRole.pant_size != undefined) {
            this.pant_size = userRole.pant_size;
          } else {
            this.pant_size = "";
          }
          if (userRole.shirt_size != undefined) {
            this.shirt_size = userRole.shirt_size;
          } else {
            this.shirt_size = "";
          }
        }
      }
      this.errorNull();
    });
  },
};
</script>
