<template>
    <div class="friends-options pt-2 px-0 team-details-tab pb-0 pl-2">
      <nav class="navbar-expand-lg">
        <div class="navbar-nav mx-auto">
          <ul class="mb-0">
            <router-link
              class="nav-link pt-0 pb-2"
              exact
              :to="{ name: 'AllTeams'}"
              :class="{ 'active': isMyTeamActive }"
            >
              <span>All Teams</span>
            </router-link>
            <router-link
              class="nav-link pt-0 pb-2"
              exact
              :to="{ name: 'GameTime'}"
              :class="{ 'active': isMyTeamActive }"
            >
              <span>My Teams</span>
            </router-link>
            <router-link
              class="nav-link pt-0 pb-2"
              exact
              :to="{ name: 'Events'}"
              :class="{ 'active': isEventsActive }"
            >
              <span>All Events</span>
            </router-link>
            <router-link
              class="nav-link pt-0 pb-2"
              exact
              :to="{ name: 'MyEvents'}"
              :class="{ 'active': isEventsActive }"
            >
              <span>My Events</span>
            </router-link>
          </ul>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    name: "GameTimeTab",
    data() {
      return {};
    },
    computed: {
      isMyTeamActive() {
        const currentUrl = localStorage.getItem('currentUrl');
        return currentUrl === '/my-teams';
      },
      isEventsActive() {
        const currentUrl = localStorage.getItem('currentUrl');
        return currentUrl === '/events';
      },
    },
    methods: {},
    mounted() {},
  };
  </script>