<template>
  <div>
    <p
      class="text-primary post-btn-options"
      @click="openPostOptions"
      v-click-outside="onClickOutside"
    >
      <img src="../../assets/images/ellipsis-h.png" alt="" />
    </p>
    <div class="sub-setting-options" v-if="postOptions">
      <ul>
        <li
          data-toggle="modal"
          @click="fetchDataUpdate()"
          data-target="#editModals"
        >
          Update post
        </li>
        <li @click="fetchDataDelete()">Delete post</li>
      </ul>
    </div>

    <UpdateInput :items="items" :openTab="openTab" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateInput from "../Posts/UpdateInput";

export default {
  name: "PostEdit",
  components: {
    UpdateInput,
  },
  props: {
    items: {
      type: Object,
    },
    openTab: {
      type: Number,
    },
  },

  data() {
    return {
      postOptions: false,
      details: {
        post_id: "",
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    openPostOptions() {
      this.postOptions = !this.postOptions;
    },

    onClickOutside() {
      this.postOptions = false;
    },

    fetchDataUpdate() {
      this.$root.$emit("checkUpdatePost", this.items);
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.postOptions = false;
      }
    },

    fetchDataDelete() {
      this.$root.$emit("checkDeletePost", this.items);
    },
  },
  mounted() {
    // document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>
