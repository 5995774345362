<template>
  <div>
    <!-- Likes and Comments Counts -->
    <div class="like-comment-share-section">
      <Like
        :items="items"
        :type="type"
        :reactionCount="reactionCount"
        :reactionUserCount="reactionUserCount"
        v-if="showReactionLoad == 0"
      />
      <content-placeholders :rounded="true" v-if="showReactionLoad == 1">
        <content-placeholders-text
          :lines="1"
          class="content-margin-reaction width-40"
        />
      </content-placeholders>
      <Comment
        :commentCount="commentCount"
        :index="index"
        v-if="showCommentLoad == 0"
      />
      <content-placeholders :rounded="true" v-if="showCommentLoad == 1">
        <content-placeholders-text :lines="1" class="content-margin width-40" />
      </content-placeholders>
    </div>

    <!-- Comments section -->
    <div v-if="commentCount > 0">
      <hr class="mb-0" />
      <div
        class="view-previous-comments"
        @click="previewMore()"
        v-if="commentCount > 2 && commentCount > slice"
      >
        View previous comments
      </div>
    </div>

    <content-placeholders :rounded="true" class="mb-10" v-if="showLoader == 1">
      <content-placeholders-heading :img="true" />
    </content-placeholders>

    <div
      class="people-comments-wrapper"
      v-for="comment in comments"
      :key="comment.id"
    >
      <div class="people-comment-inner">
        <router-link
          :to="/profile/ + comment.user_link"
          exact
          class="people-img"
          ><div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>

          <img
            @load="onImageLoad"
            v-if="comment.picture == 'yes'"
            :src="API_URL + comment.user_id + '/profile_picture.jpg?v=' + time"
            alt=""
          />
          <img
            @load="onImageLoad"
            v-else
            src="../../assets/images/user-default-new.png"
            alt="people"
          />
        </router-link>
        <div class="people-comment-text">
          <div class="people-content">
            <h6 class="name">{{ comment.user_name }}</h6>
            <p class="comment">
              {{ comment.comment }}
            </p>
          </div>
          <div class="comment-date-time">
            <span>{{ comment.created_at | formatDateComment }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Input Comments -->
    <!-- <br v-if="commentCount == 0" /> -->
    <CommentInput :items="items" />
  </div>
</template>
<script>
// import { mapActions } from "vuex";
import Like from "./Like.vue";
import Comment from "./Comment.vue";
import CommentInput from "./CommentInput.vue";
import axios from "axios";
export default {
  name: "PeopleComments",
  components: {
    Like,
    Comment,
    CommentInput,
  },
  props: {
    items: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      imgLoader: 1,
      slice: 0,
      comments: [],
      commentCount: 0,
      reactionCount: 0,
      reactionUserCount: 0,
      showCommentLoad: 1,
      showReactionLoad: 1,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      page: 1,
      index: -1,
      noPost: 0,
      showLoader: 0,
      lastPage: 1,
    };
  },
  mounted() {
    this.fetch();
    // Add comment on post Array
    this.$root.$on("newCommentArray", (post_id, newComment) => {
      if (post_id == this.items.id) {
        // Avoid Duplication
        const newComments = this.comments;
        newComments.push(newComment);
        const filtered_array = [];
        var itemsFound = {};
        for (var i = 0, l = newComments.length; i < l; i++) {
          var stringified = JSON.stringify(newComments[i]);
          if (itemsFound[stringified]) {
            continue;
          }
          filtered_array.push(newComments[i]);
          itemsFound[stringified] = true;
        }
        // Avoid Duplication
        this.comments = filtered_array;
        this.commentCount = this.commentCount + 1;
        this.slice = this.slice + 1;
      }
    });
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    previewMore() {
      if (this.page >= this.lastPage) {
        return;
      }
      this.page++;
      this.fetch();
    },
    async fetch() {
      this.showLoader = 1;
      let commentsList = await axios.get(
        `post/postComments?page=${this.page}&post_id=${this.items.id}`
      );
      if (commentsList.data.statusCode == 200) {
        this.commentCount = commentsList.data.data.comments_count;
        this.reactionCount = commentsList.data.data.reactions_count;
        this.reactionUserCount = commentsList.data.data.reaction_user_count;

        const newComments = this.comments;
        newComments.push(...commentsList.data.data.listing.data);

        // Avoid Duplication
        const filtered_array = [];
        var itemsFound = {};
        for (var i = 0, l = newComments.length; i < l; i++) {
          var stringified = JSON.stringify(newComments[i]);
          if (itemsFound[stringified]) {
            continue;
          }
          filtered_array.push(newComments[i]);
          itemsFound[stringified] = true;
        }
        // Avoid Duplication
        const sorted = filtered_array.sort(function(x, y) {
          return x.id - y.id;
        });
        this.comments = sorted;
        this.slice = this.slice + 2;
        this.lastPage = commentsList.data.data.listing.last_page;
      }
      this.showLoader = 0;
      this.showCommentLoad = 0;
      this.showReactionLoad = 0;
    },
    myFilter: function(val, idx, arr) {
      for (var i = 0; i < idx; i++) {
        if (arr[i].id === val.id) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
<style scoped>
.content-margin {
  /* margin-top: -6px; */
  margin-bottom: -10px;
  margin-left: -5px;
  width: 40px;
  margin-left: 50px;
}

.content-margin-reaction {
  /* margin-top: -6px; */
  margin-bottom: -10px;
  margin-left: -5px;
  width: 40px;
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
  margin-top: 20px;
}
</style>
