<template>
  <div>
    <!-- <button
      class="btn"
      data-toggle="modal"
      @click="fetchData(items)"
      data-target="#editModals"
    >
      Update Post
    </button> -->

    <!-- Edit Model -->
    <div
      class="modal fade bd-example-modal-lg"
      id="editModals"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <!-- <form @keyup.enter="updatePost"> -->
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Update Post</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalPostEdit"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div v-if="user" class="user-info post mb-2">
              <div class="user-img">
                <img
                  v-if="user.picture == 'yes'"
                  :src="
                    Storage_URL + user.id + '/profile_picture.jpg?v=' + time
                  "
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <div class="user-details">
                <h6 v-if="user">{{ user.name }}</h6>
              </div>
            </div>
            <div class="post-content">
              <textarea
                v-if="user"
                type="caption"
                id="caption"
                v-model="detailsPostEdit.caption"
                :placeholder="'What\'s on your mind, ' + user.name + '?'"
                class="border-0 w-100"
                v-on:keyup="checkInput"
              ></textarea>
              <input type="hidden" v-model="detailsPostEdit.single" />
              <div class="row no-gutters image-div">
                <div
                  v-for="(data, index) in rawDataEdit"
                  :key="data"
                  class="image-input image-input-active d-flex"
                >
                  <div class="image-preview">
                    <img
                      class="image-preview-img img-responsive h-100"
                      :src="data"
                    />
                    <button
                      class="btn btn-xs remove-file"
                      @click="removeFileUpdate(index)"
                    >
                      <img
                        class="remove-Image"
                        src="../../assets/cross-icon.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div
                  class="image-input image-input-tbd d-flex"
                  v-if="
                    this.detailsPostEdit.images.length <
                    this.option.maxFileCount
                  "
                >
                  <div
                    class="image-preview dropzone d-flex justify-content-center align-items-center"
                    @drop="loaddropfile"
                    @click="openinputEdit"
                  >
                    <img class="image-plus" src="../../assets/plus.png" />
                  </div>
                </div>
                <input
                  type="file"
                  class="d-none asd"
                  id="vue-file-upload-input-edit"
                  @change="addImageEdit"
                  accept="image/png, image/gif, image/jpeg"
                  multiple
                />
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="updatePost(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "UpdateInput",
  props: {
    openTab: {
      type: Number,
    },
  },

  data() {
    return {
      postOptions: false,
      details: {
        post_id: "",
      },
      items: [],
      errorMessage: null,
      time: this.$time,
      Post_URL: this.$postStorageURL,
      Storage_URL: this.$userProfileStorageURL,
      isDisabled: true,
      isLoadingArray: [],
      detailsPostEdit: {
        post_id: "",
        caption: null,
        images: [],
        single: 0,
      },
      option: {
        maxFileCount: 100,
      },
      rawDataEdit: [],
    };
  },

  mounted() {
    this.$root.$on("checkUpdatePost", (item) => {
      this.items = item;
      this.fetchData(this.items);
    });

    this.$root.$on("checkDeletePost", (item) => {
      this.deletePost(item.id);
    });
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    async deletePost(post_id) {
      var formData = new FormData();
      formData.append("post_id", post_id);
      this.$confirm(
        "About to remove this post. Continue ?",
        "Remove Post."
      ).then(() => {
        return axios
          .post(process.env.VUE_APP_API_URL + "post/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.$root.$emit("PostView");
              this.$root.$emit("Specialists");
              if (this.openTab == 1) {
                this.$root.$emit("openTabSearchSelection", 1, 0, 0);
              } else {
                this.$root.$emit("openTabSearchSelection", 3, 0, 0);
              }
            } else {
              this.errorMessage = response.data.message;
            }
          });
      });
    },

    fetchData: function (items) {
      const self = this;
      this.rawDataEdit = [];
      this.detailsPostEdit.images = [];
      this.detailsPostEdit.caption = "";
      this.detailsPostEdit.post_id = "";
      this.detailsPostEdit.caption = items.caption;
      this.detailsPostEdit.post_id = items.id;
      for (var i = 0; i < items.post_images.length; i++) {
        const urlImage = this.Post_URL + items.post_images[i].image;
        this.detailsPostEdit.images.push({
          target: items.post_images[i].image,
        });

        self.rawDataEdit.push(urlImage);
      }
      this.isDisabled = false;
    },

    loaddropfile: function (e) {
      e.preventDefault();
      e.stopPropagation();
      alert("ok");
    },

    checkInput: function () {
      if (
        (this.detailsPostEdit.caption == null ||
          this.detailsPostEdit.caption == "") &&
        this.detailsPostEdit.images.length == 0
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    openinputEdit: function () {
      document.getElementById("vue-file-upload-input-edit").click();
    },

    addImageEdit: function (event) {
      const self = this;
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        const file_ = files[i];
        this.detailsPostEdit.images.push({
          target: event.target.files[i],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawDataEdit.push(e.target.result);
        };
        reader.readAsDataURL(file_);
      }
      this.isDisabled = false;
    },

    removeFileUpdate: function (index) {
      this.detailsPostEdit.images.splice(index, 1);
      this.rawDataEdit.splice(index, 1);
      if (index <= 0) {
        this.detailsPostEdit.single = 1;
      }
      this.checkInput();
    },

    updatePost: function (index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append(
        "caption",
        this.detailsPostEdit.caption ? this.detailsPostEdit.caption : ""
      );
      formData.append("post_id", this.detailsPostEdit.post_id);
      formData.append("single", this.detailsPostEdit.single);
      this.detailsPostEdit.images.forEach((value, index) => {
        formData.append("images[" + index + "]", value.target);
      });

      return axios
        .post(process.env.VUE_APP_API_URL + "post/edit", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalPostEdit;
            elem.click();
            this.$root.$emit("PostView");
            this.$root.$emit("Specialists");

            if (this.openTab == 1) {
              this.$root.$emit("openTabSearchSelection", 1, 0, 0);
            } else {
              this.$root.$emit("openTabSearchSelection", 3, 0, 0);
            }

            this.detailsPostEdit.caption = null;
            this.detailsPostEdit.images = [];
            this.detailsPostEdit.post_id = [];
            this.rawDataEdit = [];
          } else {
            this.errorMessage = response.data.message;
          }
          this.$set(this.isLoadingArray, index, false);
        });
    },
  },
};
</script>
