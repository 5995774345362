<template>
  <div>
    <div class="post-view" v-for="post in posts" :key="post.id">
      <div class="user-info mb-4 d-flex align-items-center">
        <div class="user-img">
          <router-link
            :to="/profile/ + post.user_link"
            exact
            style="color: black"
          >
            <img
              v-if="post.propicture == 'yes'"
              :src="
                Storage_URL + post.user_id + '/profile_picture.jpg?v=' + time
              "
              alt=""
            />
            <img
              v-else
              src="../../assets/images/user-default-new.png"
              alt=""
             
            />
          </router-link>
        </div>
        <div class="user-details mr-auto">
          <router-link
            :to="/profile/ + post.user_link"
            exact
            style="color: black"
            class="user-name ml-0"
            >{{ post.user_name }}</router-link
          >
          <p>{{ post.created_at | formatDate }}</p>
        </div>
        <!-- </div> -->
        <PostEditSpecial
          :items="post"
          v-if="user && user.id == post.user_id"
          style="float: right"
        ></PostEditSpecial>
      </div>
      <div class="post-content">
        <PostContent :items="post.caption"></PostContent>
        <div class="post-images">
          <div class="gallery-container">
            <lightbox :items="post.post_images"></lightbox>
          </div>
        </div>
      </div>
      <PeopleComments :items="post" :type="type" />
    </div>
    <p class="post-view" v-if="noPost == 1">No specialist posts found.</p>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>

    <div
      v-if="posts.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>
  </div>
</template>
<script>
import axios from "axios";
import lightbox from "../ImageGrid/lightbox";
import "../ImageGrid/lightbox";
import PostContent from "../Posts/PostContent";
import PostEditSpecial from "../Posts/PostEditSpecial";
import PeopleComments from "../Posts/PeopleComments.vue";
import { mapGetters } from "vuex";

export default {
  name: "Specialists",
  components: {
    lightbox,
    PostContent,
    PostEditSpecial,
    PeopleComments,
  },
  data() {
    return {
      type: "specialist",
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      posts: [],
      showLoader: 0,
      noPost: 0,
      page: 1,
      lastPage: 1,
      readMoreActivated: false,
    };
  },
  methods: {
    async fetch() {
      this.showLoader = 1;
      let posts = await axios.get(
        `post/specialistPostListing?page=${this.page}`
      );
      if (posts.data.statusCode == 200) {
        this.posts.push(...posts.data.data.listing.data);
        this.lastPage = posts.data.data.listing.last_page;
        this.noPost = posts.data.data.listing.data.length > 0 ? 0 : 1;
        this.showLoader = 0;
      } else {
        this.errorMessage = posts.data.message;
        this.showLoader = 0;
      }
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetch();
    },
    activateReadMore() {
      this.readMoreActivated = !this.readMoreActivated;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.fetch(1);
    this.$root.$on("Specialists", () => {
      this.page = 1;
      this.lastPage = 1;
      this.posts = [];
      this.fetch(1);
    });
  },
};
</script>
