<template>
  <div>
    <p class="m-0 mb-2 p-0">
      <span class="wrappper" v-if="!readMoreActivated && items">{{
        items.slice(0, 200)
      }}</span>
      <a
        class="text-primary font-weight-bold"
        v-if="!readMoreActivated"
        @click="activateReadMore"
      >
        <span v-if="totalcharacter >= 200" class="cursor-pointer ml-1">
          (See More)</span
        >
      </a>
      <span class="wrappper" v-if="readMoreActivated" v-html="items"></span>
      <a
        class="text-primary font-weight-bold cursor-pointer"
        v-if="readMoreActivated"
        @click="activateReadMore"
      >
        <span class="ml-1">(See Less)</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "PostContent",
  props: {
    items: {
      type: String,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      readMoreActivated: false,
      totalcharacter: 0,
    };
  },

  methods: {
    activateReadMore() {
      this.readMoreActivated = !this.readMoreActivated;
      this.charCount();
    },
    charCount: function () {
      if (this.items) {
        this.totalcharacter = this.items.length;
      }
    },
  },
  mounted() {
    this.charCount(1);
  },
};
</script>