<template>
  <div class="shim-div">
    <div class="p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
    <div class="mt-3 p-0">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "chatShimmers",

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {},
  mounted() {},
};
</script>
<style scoped>
.shim-div {
  height: calc(100vh - 121px);
  min-width: calc(100vw - 72vw);
  background-color: #ffffff;
  padding-left: 15px;
}
@media screen and (max-width: 900px) {
  .shim-div {
    width: 100vw;
  }
}
</style>
