<template>
  <div class="comment-post">
    <img src="../../assets/images/notification.png" alt="Like" />
    <span>{{commentCount}}</span>
  </div>
</template>
<script>
export default {
  name: "Comment",
  data() {
    return {
      count:0
    }
  },
  props: {
    commentCount: {
      type: Number,
    },
    index:{
      type:Number,
    }
  },
};

</script>
