<template>
  <div>

      <div class="row no-gutters mb-2" v-if="showLoader == 1">
        <div class="col-12 p-0">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 p-0 my-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
        <div class="col-12 p-0 mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="true" />
          </content-placeholders>
        </div>
      </div>

    <div v-else>
      <div class="search-container border-0">
      <form>
        <button type="submit">
          <img src="../../../../assets/images/search.png" alt="" />
        </button>
        <input
          v-model="searchValue"
          id="search-input"
          type="text"
          placeholder="Search in teammates"
          name="search"
          autocomplete="new-password"
          v-on:keydown.enter.prevent
        />
      </form> 
    </div>
    <div class="team-member">
      <h6>Teammate</h6>
      <span class="nav-counter-team" v-if="selectedRoomTeam.users">{{ selectedRoomTeam.users.length }}</span>
    </div>
    <div class="team-members">
      <div class="team-info" v-for="user in filteredMember" :key="user.id">
        <div class="team-details">
          <div class="team-image position-relative">
            <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
            <img
                @load="onImageLoad"
                :src="$userProfileStorageURL + user.c_id +'/profile_picture.jpg?v='+$time"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
            />
          </div>
          <div class="team-name-role">
            <h6 :id="user.id + '' + user.c_id">{{ user.username }} <span v-if="user.nickname">( {{ user.nickname }} )</span></h6>
            <span v-if="user.adminRole == '1'">Admin</span>
            <p v-if="user.adminRole == '0'"></p>
            <span v-if="user.fanRole == '1'">Fan</span>
          </div>
        </div>
        <MemberOptions
          :items="user"
          :selectedRoomTeam="selectedRoomTeam"
          :currentUserId="currentUserId"
          :currentIsAdmin="currentIsAdmin"
          :currentIsFan="currentIsFan"
          v-if="currentTeamIsActive == 1"
        />
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import MemberOptions from "../ChatSettingComponents/MemberOptions.vue";
import axios from "axios";

export default {
  name: "MembersInfos",
  data: function () {
    return {
      searchValue: "",
      imgLoader: 1,
      teammates_id: [],
      userProfile: [],
      showLoader: 0,
      finalSelectedRoomTeam: [],
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
    currentUserId: {
      type: String,
    },
  },
  components: {
    MemberOptions,
  },
watch: {
    'selectedRoomTeam.users': {
    handler(newUsers, oldUsers) {
      if (newUsers.length !== oldUsers.length) {
        this.fetchTeammatesNickName();
      } else {
        this.updateUserNicknames();
      }
    },
    deep: true, 
  },
  },
  created() {
  if (this.selectedRoomTeam && Array.isArray(this.selectedRoomTeam.users)) {
    this.selectedRoomTeam.users.forEach(user => {
      this.teammates_id.push(user.c_id);
    });
    this.fetchTeammatesNickName();
  }
},
computed: {
  filteredMember() {
  let tempMembers = this.finalSelectedRoomTeam.filter(item => {
    if (item.username) {
      return item.username.toUpperCase().includes(
        this.searchValue.toUpperCase().trim().replace(/\s+/g, " ")
      );
    }
    return false;
  });

  const sortedMembers = tempMembers.slice().sort((a, b) => {
    const lastWordA = this.getLastWord(a.username);
    const lastWordB = this.getLastWord(b.username);

    return lastWordA.localeCompare(lastWordB);
  });

  return sortedMembers;
},

},
  methods: {
    getLastWord(username) {
      const words = username.trim().split(" ");
      return words[words.length - 1].toUpperCase();
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    
    async fetchTeammatesNickName() {
    this.showLoader = 1;
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}chat/getTeammatesNickName`,
        { teammates_id: this.teammates_id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.data.data) {
        this.userProfiles = response.data.data;
        this.updateUserNicknames();        
        this.showLoader = 0;
      } else {
        console.error("Failed to fetch teammates' nicknames:", response.data.message);
        this.showLoader = 0;
      }
    } catch (error) {
      console.error("Error fetching teammates' nicknames:", error);
      this.showLoader = 0;
    }
  },
  

  updateUserNicknames() {
    this.finalSelectedRoomTeam = this.selectedRoomTeam.users.map(user => {
      const userProfileData = this.userProfiles.find(profile => parseInt(profile.user_id) === parseInt(user.c_id));

      if (userProfileData) {
        return { ...user, nickname: userProfileData.nick_name, fname: userProfileData.fname, lname: userProfileData.lname  };
      } else {
        return user;
      }
    });
  }

  },
  mounted() {
      this.fetchTeammatesNickName();
  },
};
</script>

<style>
</style>