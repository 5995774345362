<template>
  <div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="ModalField"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModal">New Field</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEventModalfield"
              @click="closefieldModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <span
              class="label-type error event-error mt-2"
              v-if="errorMessage != ''"
            >
              Field name already added.
            </span>
            <br v-if="errorMessage != ''" />
            <div class="container-fluid no-pdd mt-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="fieldName"
                      v-model="fieldName"
                      @keyup="errorNull"
                      required
                    />
                    <span class="placeholder">Field Name</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <!-- Add your field-specific input elements here -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      @keyup="errorNull"
                      required
                      v-model="streamId"
                    />
                    <span class="placeholder">Stream Id</span>
                  </div>
                </div>
              </div>
              <!-- Add more rows for additional fields if needed -->
            </div>
          </div>

          <div class="event-btns-wrapper px-3 pt-0 pb-3 justify-content-end">
            <button
              v-if="this.isDisabledGame == false"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="createField(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Field</span>
            </button>
            <button
              v-else-if="this.isDisabledGame == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Field</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewField",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      userName: localStorage.getItem("userName"),
      isDisabledGame: true,
      showLoaderGame: 0,
      address: "",
      parkID: "",
      fieldName: "",
      streamId: "",
      marker: { position: { lat: 10, lng: 10 } },
      flag: 0,
      parkNameError: "",
      errorMessage: "",
      isLoadingArray: [],
    };
  },

  methods: {
    closefieldModal: function() {
      var Tabs = 1;
      if (this.flag == 1) {
        this.$root.$emit("checkUpdateEdit", Tabs);
      } else if (this.flag == 2) {
        this.$root.$emit("editedField", Tabs);
      } else if (this.flag == 3) {
        this.$root.$emit("editedGameField", Tabs);
      } else if (this.flag == 4) {
        this.$root.$emit("addTournamentGameField", Tabs);
      } else {
        this.$root.$emit("addedField", Tabs);
      }
      var elem = this.$refs.closeEventModalfield;
      elem.click();
    },
    errorNull: function() {
      if (
        this.streamId === "" ||
        this.fieldName === "" ||
        this.errorMessage !== ""
      ) {
        this.isDisabledGame = true;
      } else {
        this.isDisabledGame = false;
      }
      this.errorMessage = "";
    },

    // Create Park
    async createField(index) {
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      // formData.append("address", this.address);
      formData.append("field_name", this.fieldName);
      // formData.append("lat", this.marker.position.lat);
      // formData.append("lng", this.marker.position.lng);
      formData.append("stream_content_id", this.streamId);
      formData.append("park_id", this.parkID);

      await axios
        .post(process.env.VUE_APP_API_URL + "game/parkFieldCreate", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var Tabs = 1;
            if (this.flag == 1) {
              this.$root.$emit("checkUpdateEdit", Tabs);
            } else if (this.flag == 2) {
              this.$root.$emit("editedField", Tabs);
            } else if (this.flag == 3) {
              this.$root.$emit("editedGameField", Tabs);
            } else if (this.flag == 4) {
              this.$root.$emit("addTournamentGameField", Tabs);
            } else {
              this.$root.$emit("addedField", Tabs);
            }
            var elem = this.$refs.closeEventModalfield;
            elem.click();
            this.$set(this.isLoadingArray, index, false);
          } else {
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArray, index, false);
            this.isDisabledGame = true;
          }
        });
    },

    checkCurdField(index) {
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, false);
      // this.address = "";
      this.fieldName = "";
      this.streamId = "";
      this.parkNameError = "";
      this.errorNull();
    },
  },
  mounted: function() {
    this.$root.$on("AddField", () => {
      this.checkCurdField(1);
    });
    this.$root.$on("AddFieldInitiate", (data) => {
      this.parkID = data.park_id;
      this.flag = data.tabs;
    });
  },
};
</script>
<style>
.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

a {
  font-size: 12px;
  font-weight: 500;
  margin: 0 7px;
  color: #188ef5 !important;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
div.form-group .add-notes {
  font-size: 14px;
}
</style>
