<template>
  <div>
    <div
      id="feedBackModalReport"
      aria-hidden="true"
      aria-labelledby="feedBackModalReport"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <!--   tabindex="-1"   -->
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6>Give us your feedback.</h6>

            <button
              ref="closeModalResponseFeedback"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <form @submit.prevent="reportPost" enctype="multipart/form-data">
              <div class="row no-gutters">
                <div class="col-12">
                  <div
                    class="form-group"
                    style="margin-bottom: 15px !important"
                  >
                    <select
                      id="postReportType"
                      v-model.trim="$v.feedbackresponse.postReportType.$model"
                      :class="{
                        'is-invalid': validationStatus(
                          $v.feedbackresponse.postReportType
                        ),
                      }"
                      class="inputProblem form-control select2"
                      name="postReportType"
                      aria-hidden="true"
                    >
                      <option :key="'allowance_id'" value="" disabled>
                        Select Category
                      </option>

                      <option
                        v-for="(item, index) in selectBox"
                        v-bind:key="index"
                        :value="item.id"
                        class="feedback-options"
                      >
                        {{ item.title }}
                      </option>
                    </select>

                    <div
                      v-if="
                        !$v.feedbackresponse.postReportType.required &&
                        validationserror
                      "
                      class="invalid-feedback error-message"
                    >
                      Select Category
                    </div>
                  </div>

                  <div class="form-group">
                    <textarea
                      id="description"
                      v-model.trim="$v.feedbackresponse.description.$model"
                      :class="{
                        'is-invalid': validationStatus(
                          $v.feedbackresponse.description
                        ),
                      }"
                      class="inputProblemDescription"
                      placeholder="Description"
                      rows="5"
                    ></textarea>

                    <div
                      v-if="
                        !$v.feedbackresponse.description.required &&
                        validationserror
                      "
                      class="invalid-feedback error-message"
                      style="top: 7%"
                    >
                      Enter Description
                    </div>

                    <div
                      v-if="
                        !$v.feedbackresponse.description.maxLength &&
                        validationserror
                      "
                      class="invalid-feedback error-message"
                    >
                      max characters 255
                    </div>
                  </div>
                </div>
              </div>

              <div class="event-btns-wrapper justify-content-end">
                <!--        data-dismiss="modal"        -->

                <!-- <div v-if="loading" v-cloak>
                    <img
                      class="loaderSign"
                      style="margin-right: 12px; height: 28px"
                      src="../../assets/loader.gif"
                    />
                  </div>
                  <button class="continueBtn p-3" data-toggle="modal" type="submit">
                    Submit Feedback
                  </button> -->

                <button
                  v-if="this.isDisabled == false"
                  type="submit"
                  class="btn btn-primary w-100"
                  @click="reportPost"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="loading" v-cloak class="lds-dual-ring"></div>

                  <span v-else class="report-submit">Submit</span>
                </button>
                <button
                  v-if="this.isDisabled == true"
                  type="button"
                  class="btn btn-primary-disabled w-100"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="loading" v-cloak class="lds-dual-ring"></div>

                  <span v-else class="report-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import { maxLength, required } from "vuelidate/lib/validators";
export default {
  name: "postReportModal",
  props: {
    items: {
      type: Object,
    },
    openTab: {
      type: Number,
    },
  },
  data() {
    return {
      opts: [],
      allowance_id: [],
      selectBox: "",
      loading: false,
      feedbacktype: 1,
      feedbackresponse: {
        postReportType: "",
        description: "",
      },
      validationserror: false,
      isLoadingArray: [],
      isDisabled: true,
    };
  },
  validations: {
    feedbackresponse: {
      postReportType: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(1000),
      },
    },
  },

  methods: {
    async feedbackType(e) {
      this.feedbacktype = e;
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    closeModal: function () {
      this.validationserror = false;
      this.feedbackresponse.postReportType = "";
      this.feedbackresponse.description = "";
      this.getselectedoptions();
      $(".select2").val("").trigger("change");
    },
    getselectedoptions() {
      this.allowances = [
        {
          id: 1,
          name: "selectcategory",
        },
      ];
    },
    reportPost: function (event) {
      this.validationserror = true;
      this.$v.feedbackresponse.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.feedbackresponse.$pending || this.$v.feedbackresponse.$error)
        return;

      this.loading = true;
      setTimeout(
        function () {
          this.loading = false;
        }.bind(this),
        1000
      );

      const formData = new FormData();

      formData.append("category_id", this.feedbackresponse.postReportType);
      formData.append("description", this.feedbackresponse.description);
      formData.append("post_id", this.items.id);

      axios
        .post(
          process.env.VUE_APP_API_URL + "feedback/report-post-store",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res);
          event.target.reset();
          var elem = this.$refs.closeModalResponseFeedback;
          elem.click();
          this.$root.$emit("PostView");
          this.$root.$emit("Specialists");
          this.$root.$emit("OtherProfilePost");
          this.closeModal();
          this.isDisabled = true;
          this.$fire({
            text: "Post has been Reported successfully.",
            type: "success",
            showConfirmButton: false,
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
        });
    },
    //  fetchedCats() {
    //   this.opts = [];
    //         this.selectBox.forEach((value) => {
    //           this.opts.push({ text: value.title, value: value.id });
    //         });
    // },
    onSelectChange(val) {
      this.feedbackresponse.postReportType = val;
      if (this.feedbackresponse.postReportType == "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.getselectedoptions();
    let self = this;
    $(".select2")
      .select2()
      .on("change", function () {
        self.onSelectChange(this.value);
      });
    this.$root.$on("catForREPORT", (data) => {
      this.selectBox = data;
    });
  },
};
</script>
<style scoped></style>
