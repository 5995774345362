<template>
  <div class="no-chat-wrapper">

    <div class="no-chat-image">
      <img src="../../assets/Placeholder.png" alt="" />
      <div class="no-chat-text">
        <p v-if="open == 'Individual'">There are no chats to display at the moment. </p>
        <p v-else>There are no teams to display at the moment.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoChat",
  data() {
    return {
      open: "",
    };
  },
  mounted() {
    this.open = localStorage.getItem("openTabChat");
  },
};
</script>