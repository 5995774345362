<template>
  <div>
    <div class="like-post">
      <img @click="changeColor" :src="imageLike" alt="Like" />
      <span
        variant="primary"
        v-b-modal="'myModal' + items.id + type"
        @click="getUsers"
      >
        <span>{{ textUpdate }}</span
        ><span v-if="textUpdate == ''">{{ likeCount }}</span>
      </span>
    </div>
    <b-modal
      :id="'myModal' + items.id + type"
      size="lg"
      title="People who like this"
      :hide-footer="true"
      centered
    >
      <div v-if="showLoader == 1">
        <content-placeholders class="mb-2" :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <content-placeholders class="mb-2" :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <content-placeholders class="mb-2" :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
        <content-placeholders class="mb-2" :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
      <div class="tab-content" id="myTabContent" v-if="showLoader == 0">
        <div v-if="usersLikes.length > 0">
          <h6>People</h6>

          <!-- Profiles on All -->
          <div>
            <div
              class="people-wrapper"
              v-for="profile in usersLikes"
              :key="profile.id"
            >
              <div class="people-img" v-if="user">
                <span v-if="user.user_link == profile.user_link">
                  <router-link to="/profile" exact class="mx-0">
                    <img
                      v-if="profile.picture == 'yes'"
                      :src="
                        Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                      "
                      alt=""
                    />
                    <img
                      v-else
                      src="../../assets/images/user-default-new.png"
                      alt=""
                    />
                  </router-link>
                </span>
                <span v-else>
                  <router-link
                    :to="/profile/ + profile.user_link"
                    exact
                    class="mx-0"
                  >
                    <img
                      v-if="profile.picture == 'yes'"
                      :src="
                        Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                      "
                      alt=""
                    />
                    <img
                      v-else
                      src="../../assets/images/user-default-new.png"
                      alt=""
                    />
                  </router-link>
                </span>
              </div>
              <div class="people-info" v-if="user">
                <span v-if="user.user_link == profile.user_link">
                  <router-link to="/profile" exact class="mx-0"
                    >{{ profile.fname }} {{ profile.lname }}</router-link
                  >
                </span>
                <span v-else>
                  <router-link
                    :to="/profile/ + profile.user_link"
                    exact
                    class="mx-0"
                    >{{ profile.fname }} {{ profile.lname }}</router-link
                  >
                </span>
                <p v-if="profile.currentUser == 0 && profile.friend == 3">
                  Friend
                </p>
                <p v-if="profile.currentUser == 0 && profile.friend == 1">
                  Request Sent
                </p>
                <p v-if="profile.currentUser == 0 && profile.friend == 2">
                  Request Received
                </p>
              </div>
              <div
                class="add-friend"
                :id="profile.user_id"
                v-if="profile.currentUser == 0 && profile.friend == 0"
              >
                <img src="../../assets/add-friend.png" />
                <span class="tool-tip-text">Add Friend</span>
              </div>
              <div
                class="message-friend"
                v-if="profile.currentUser == 0 && profile.friend == 3"
                data-toggle="modal"
                data-target="#individualModalDirect"
              >
                <img src="../../assets/message.png" />
                <span class="tool-tip-text">Message</span>
              </div>
            </div>
          </div>

          <!-- <button class="see-all-friends">
            <span>See All</span>
          </button> -->
        </div>
        <div class="see-all-seen-post-wrapper" v-if="usersLikes.length == 0">
          <p>{{ error }}</p>
        </div>
        <div class="join-footer"></div>
      </div>
      <div
        v-if="usersLikes.length"
        v-observe-visibility="handleScrolledToButtom"
      ></div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Like",
  props: {
    items: {
      type: Object,
    },
    reactionCount: {
      type: Number,
    },
    reactionUserCount: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      imageLike: require("../../assets/images/heart-empty.png"),
      likeBlue: require("../../assets/images/heart-like.png"),
      clicked: 0,
      like_status: 0,
      likeCount: 0,
      userOwn: 0,
      textUpdate: "0 People like this",
      usersLikes: [],
      showLoader: 0,
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      page: 1,
      lastPage: 1,
      error: "",
    };
  },
  methods: {
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.getUsers();
    },
    changeColor() {
      this.clicked = !this.clicked;
      this.$root.$emit("changeColorLike", this.clicked, this.items.id);
      this.like_status = 1;
      if (this.clicked == 1) {
        this.createlike();
      } else {
        this.like_status = 0;
        this.unLike();
      }
    },
    createlike: function () {
      var formData = new FormData();
      this.likeCount = this.likeCount + 1;
      if (this.likeCount == "1") {
        this.textUpdate = "You like this";
      }
      this.infoUp();
      formData.append("post_id", this.items.id);
      formData.append("user_id", this.user.id);
      formData.append("react_id", 1);
      formData.append("like_status", this.like_status);

      return axios
        .post(process.env.VUE_APP_API_URL + "post/like", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {});
    },
    unLike: function () {
      var formData = new FormData();
      if (this.likeCount >= 0) {
        this.likeCount = this.likeCount - 1;
      } else {
        this.likeCount = 0;
      }

      this.infoUp();
      formData.append("post_id", this.items.id);
      formData.append("user_id", this.user.id);
      formData.append("react_id", 1);
      formData.append("like_status", this.like_status);

      return axios
        .post(process.env.VUE_APP_API_URL + "post/like", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {});
    },
    infoUp() {
      // if (this.reactionUserCount==1) {
      //   this.textUpdate = "You like this";
      // }
      // else if (this.likeCount == 0) {
      this.textUpdate = this.likeCount + " People like this";
      // }
      // else if (this.likeCount >= 1 && this.reactionUserCount==1) {
      //   this.textUpdate = this.likeCount + " People like this";
      // }
    },
    getUsers() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("post_id", this.items.id);
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            `post/getUserForlikes?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.lastPage = response.data.data.last_page;
            if (this.lastPage == 1) {
              this.usersLikes = response.data.data.data;
            } else {
              this.usersLikes.push(...response.data.data.data);
            }
          } else {
            this.error = response.data.message;
          }
          this.showLoader = 0;
        });
    },
  },
  mounted() {
    // Add count update
    this.$root.$on("changeColorLike", (clicked, post_id) => {
      if (post_id == this.items.id) {
        this.like_status = 1;
        if (clicked == 1) {
          this.imageLike = this.likeBlue;
          // this.likeCount = this.likeCount + 1;
        } else {
          this.imageLike = require("../../assets/images/heart-empty.png");
          this.like_status = 0;
          // this.likeCount = this.likeCount - 1;
        }
      }
    });

    // Add count update
    this.$root.$on("reactionCountUpdate", (post_id, count) => {
      if (post_id == this.items.id) {
        this.likeCount = count;
        this.infoUp();
      }
    });
    this.likeCount = this.reactionCount;
    if (this.reactionUserCount == 1) {
      this.clicked = 1;
    }
    // if (this.likeCount == 1 && this.reactionUserCount==1) {
    //   this.textUpdate = "You like this";
    // }
    // else if (this.likeCount >= 1 && this.reactionUserCount==1) {
    this.textUpdate = this.likeCount + " People like this";
    // }
    // else if (this.likeCount == 0) {
    //   this.textUpdate = this.likeCount + " People like this";
    // }
    if (this.clicked == 1) {
      this.imageLike = this.likeBlue;
    }
  },
};
</script>
