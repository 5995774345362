<template>
  <div>
    <content-placeholders :rounded="true" class="mb-10" v-if="showLoader == 1">
      <content-placeholders-heading :img="true" />
    </content-placeholders>
    <div class="comment-input" v-if="user">
      <div class="people-img">
        <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
        <img
        @load="onImageLoad"
          v-if="user.picture == 'yes'"
          :src="API_URL + user.id + '/profile_picture.jpg?v=' + time"
          alt=""
        />
        <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="user" />
      </div>
      <!-- <input
        type="text"
        v-model="writtenComment"
        placeholder="Write a comment"
        @keyup.enter="createComment()"
      /> -->
      <div class="wrapper">
        <input
          ref="inputCommentField"
          v-model="writtenComment"
          placeholder="Write a comment"
          @keyup.enter="createComment()"
          class="regular-input"
          maxlength="150"
        />

        <emoji-picker @emoji="append" :search="search">
          <button
            class="emoji-invoker"
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              class="bi bi-emoji-smile"
              viewBox="0 0 24 24"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"
              />
            </svg>
          </button>
          <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
            <div
              class="emoji-picker"
              :style="{ top: display.y + 'px', left: display.x + 'px' }"
            >
              <!-- <div class="emoji-picker__search">
                <input
                  type="text"
                  v-model="search"
                  v-focus
                  placeholder="Search Emoji"
                />
              </div> -->
              <div>
                <div v-for="(emojiGroup, category) in emojis" :key="category">
                  <h5>{{ category }}</h5>
                  <div class="emojis">
                    <span
                      v-for="(emoji, emojiName) in emojiGroup"
                      :key="emojiName"
                      @click="insert(emoji)"
                      :title="emojiName"
                      >{{ emoji }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </emoji-picker>
      </div>
      <button class="comment-btn" @click="createComment()">
        <img src="../../assets/images/send.png" alt="send-btn" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import EmojiPicker from "vue-emoji-picker";
import $ from "jquery";

export default {
  name: "CommentInput",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      writtenComment: "",
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      showLoader: 0,
      search: "",
      imgLoader: 1,
    };
  },
  components: {
    EmojiPicker,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    onEnter: function () {},
    createComment: function () {
      if (this.writtenComment != "") {
        this.showLoader = 1;
        var formData = new FormData();
        formData.append("post_id", this.items.id);
        formData.append("comment", this.writtenComment);
        formData.append("post_user_id", this.items.user_id);
        formData.append("user_id", this.user.id);
        formData.append("comment_type_id", 1);
        formData.append("comment_state", 1);
        $(".regular-input").keypress(function () {
          var value = $(this).val();
          if (value.length != "") {
            var inputs = $(this).find(":input");
            inputs.eq(inputs.index(this)).focus();
          }
        });
        this.writtenComment = "";

        return axios
          .post(process.env.VUE_APP_API_URL + "post/enterComment", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.$root.$emit(
                "newCommentArray",
                this.items.id,
                response.data.data
              );
              this.showLoader = 0;
              // Notification
              var formData = new FormData();
              formData.append("post_id", this.items.id);
              formData.append("comment", JSON.stringify(response.data.data));
              formData.append("post_user_id", this.items.user_id);
              this.writtenComment = "";

              return axios
                .post(
                  process.env.VUE_APP_API_URL + "post/sendCommentsNotification",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then(() => {});
            }
          });
      }
    },
    append(emoji) {
      this.writtenComment += emoji;
      this.$refs.inputCommentField.focus();
    },
    autoSize() {
      var text = $(".regular-input");

      text.each(function () {
        $(this).attr("rows", 1);
        resize($(this));
      });

      text.on("input", function () {
        resize($(this));
      });

      function resize($text) {
        $text.css("height", "auto");
        $text.css("height", $text[0].scrollHeight + "px");
      }
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
<style>
</style>
