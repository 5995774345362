<template>
  <div>
    <p
      class="text-primary post-btn-options"
      @click="openPostOptions"
      v-click-outside="onClickOutside"
    >
      <img src="../../assets/images/ellipsis-h.png" alt="" />
    </p>
    <div class="sub-setting-options" v-if="postOptions">
      <ul>
        <li data-target="#feedBackModalReport" @click="fetchReportPostType" data-toggle="modal">
          Report Post
        </li>
      </ul>
    </div>
    <postReportMOdal :items="items" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import postReportMOdal from "../Posts/postReportModal"

export default {
  name: "PostReport",
  components: {
    postReportMOdal
  },
  props: {
    items: {
      type: Object,
    },
    openTab: {
      type: Number,
    },
  },

  data() {
    return {
      postOptions: false,
      details: {
        post_id: "",
      },
      selectBox:'',
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    openPostOptions() {
      this.postOptions = !this.postOptions;
    },

    onClickOutside() {
      this.postOptions = false;
    },

    callReport() {
      this.$root.$emit("checkReportPost", this.items);
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.postOptions = false;
      }
    },

    fetchDataDelete() {
      this.$root.$emit("checkDeletePost", this.items);
    },

    fetchReportPostType()
    {
       axios
        .get(process.env.VUE_APP_API_URL + "feedback/types/list?id=3")
        .then((response) => {
          this.selectBox = response.data.data;
          this.$root.$emit("catForREPORT" , this.selectBox);
        });
    }
  },
  mounted() {
    // document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>
