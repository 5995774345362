<template>
  <div>
    <div class="events">
      <router-link to="/profile" style="color: black" class="m-0">
        <div v-if="user" class="user-info">
          <div class="user-img">
            <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
            <img
            @load="onImageLoad"
              v-if="user.picture == 'yes'"
              :src="API_URL + user.id + '/profile_picture.jpg?v='+time"
              alt=""
            />
            <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="" />
          </div>
          <div class="user-details">
            <span class="user-name ml-0">
              {{ user.name }}
            </span>
            <div class="user-link">@{{ user.user_link }}</div>
          </div>
        </div>
        <content-placeholders class="ml-3" :rounded="true" v-else>
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </router-link>
      <div class="events-actions">
        <ul class="events-actions-list">
          <li class="events-actions-item">
            <router-link class="event-link" to="/profile">
              <img src="../../assets/images/search-posts.png" alt="" />
              Post</router-link
            >
          </li>
          <li class="events-actions-item">
            <router-link class="event-link" to="/friends">
              <img src="../../assets/images/search-people.png" alt="" />
              Friends</router-link
            >
          </li>
          <!-- <li class="events-actions-item">
            <router-link class="event-link" to="">
              <img src="../../assets/memories.png" alt="" />
              Memories</router-link
            >
          </li>
          <li class="events-actions-item">
            <router-link class="event-link" to="">
              <img src="../../assets/events.png" alt="" />
              Events</router-link
            >
            <div class="counter-div">
              <div class="major-counter">
                <p>{{ counter }}</p>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Events",
  data() {
    return {
      time:this.$time,
      API_URL: this.$userProfileStorageURL,
      counter: 9,
      imgLoader: 1,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
