var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"like-post"},[_c('img',{attrs:{"src":_vm.imageLike,"alt":"Like"},on:{"click":_vm.changeColor}}),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('myModal' + _vm.items.id + _vm.type),expression:"'myModal' + items.id + type"}],attrs:{"variant":"primary"},on:{"click":_vm.getUsers}},[_c('span',[_vm._v(_vm._s(_vm.textUpdate))]),(_vm.textUpdate == '')?_c('span',[_vm._v(_vm._s(_vm.likeCount))]):_vm._e()])]),_c('b-modal',{attrs:{"id":'myModal' + _vm.items.id + _vm.type,"size":"lg","title":"People who like this","hide-footer":true,"centered":""}},[(_vm.showLoader == 1)?_c('div',[_c('content-placeholders',{staticClass:"mb-2",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1),_c('content-placeholders',{staticClass:"mb-2",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1),_c('content-placeholders',{staticClass:"mb-2",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1),_c('content-placeholders',{staticClass:"mb-2",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}})],1)],1):_vm._e(),(_vm.showLoader == 0)?_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[(_vm.usersLikes.length > 0)?_c('div',[_c('h6',[_vm._v("People")]),_c('div',_vm._l((_vm.usersLikes),function(profile){return _c('div',{key:profile.id,staticClass:"people-wrapper"},[(_vm.user)?_c('div',{staticClass:"people-img"},[(_vm.user.user_link == profile.user_link)?_c('span',[_c('router-link',{staticClass:"mx-0",attrs:{"to":"/profile","exact":""}},[(profile.picture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                      profile.user_id +
                      '/profile_picture.jpg?v=' +
                      _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})])],1):_c('span',[_c('router-link',{staticClass:"mx-0",attrs:{"to":/profile/ + profile.user_link,"exact":""}},[(profile.picture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                      profile.user_id +
                      '/profile_picture.jpg?v=' +
                      _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})])],1)]):_vm._e(),(_vm.user)?_c('div',{staticClass:"people-info"},[(_vm.user.user_link == profile.user_link)?_c('span',[_c('router-link',{staticClass:"mx-0",attrs:{"to":"/profile","exact":""}},[_vm._v(_vm._s(profile.fname)+" "+_vm._s(profile.lname))])],1):_c('span',[_c('router-link',{staticClass:"mx-0",attrs:{"to":/profile/ + profile.user_link,"exact":""}},[_vm._v(_vm._s(profile.fname)+" "+_vm._s(profile.lname))])],1),(profile.currentUser == 0 && profile.friend == 3)?_c('p',[_vm._v(" Friend ")]):_vm._e(),(profile.currentUser == 0 && profile.friend == 1)?_c('p',[_vm._v(" Request Sent ")]):_vm._e(),(profile.currentUser == 0 && profile.friend == 2)?_c('p',[_vm._v(" Request Received ")]):_vm._e()]):_vm._e(),(profile.currentUser == 0 && profile.friend == 0)?_c('div',{staticClass:"add-friend",attrs:{"id":profile.user_id}},[_c('img',{attrs:{"src":require("../../assets/add-friend.png")}}),_c('span',{staticClass:"tool-tip-text"},[_vm._v("Add Friend")])]):_vm._e(),(profile.currentUser == 0 && profile.friend == 3)?_c('div',{staticClass:"message-friend",attrs:{"data-toggle":"modal","data-target":"#individualModalDirect"}},[_c('img',{attrs:{"src":require("../../assets/message.png")}}),_c('span',{staticClass:"tool-tip-text"},[_vm._v("Message")])]):_vm._e()])}),0)]):_vm._e(),(_vm.usersLikes.length == 0)?_c('div',{staticClass:"see-all-seen-post-wrapper"},[_c('p',[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_c('div',{staticClass:"join-footer"})]):_vm._e(),(_vm.usersLikes.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleScrolledToButtom),expression:"handleScrolledToButtom"}]}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }