<template>
  <div>
    <!-- Button trigger modal -->
    <a
      data-toggle="modal"
      data-target="#exampleModal"
      ref="openModalXl"
      class="btn btn-primary create-game d-flex align-items-center mx-0"
      @click="checkCurd"
    >
      Add Game
    </a>

    <!-- Modal -->
    <div
      class="modal fade" 
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">New Game</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEventModal"
              @click="closePark"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid no-pdd">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="gameName"
                      v-model="gameName"
                      @keyup="errorNull"
                      required
                      maxlength="30"
                    />
                    <span class="placeholder">Game Name</span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="opponentName"
                      v-model="opponentName"
                      @keyup="errorNull"
                      required
                      maxlength="30"
                    />
                    <span class="placeholder">Opponent Name</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group pdd-adjust-type">
                    <label for="startDateCheck">Opponent Country</label>
                    <country-select
                      v-model="country"
                      :country="country"
                      v-bind:countryName="true"
                      topCountry="United States"
                      @change.native="errorNull"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label for="startTimeCheck">Opponent Region</label>
                    <region-select
                      v-model="region"
                      :country="country"
                      v-bind:regionName="true"
                      :countryName="true"
                      :region="region"
                      @change.native="errorNull"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="opponentCity">Opponent City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="opponentCity"
                      v-model="opponentCity"
                      @keyup="errorNull"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label class="typo__label" for="ajaxsingle"
                      >Event Alert</label
                    >
                    <select
                      class="form-control form-control-lg"
                      id="eventAlert"
                      v-model="eventAlert"
                      @change="errorNull"
                    >
                      <option value="No Alert" selected>No Alert</option>
                      <option
                        v-for="option in eventAlertOptions"
                        :key="option.id"
                      >
                        {{ option.statement }} {{ option.hours }} hour(s)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="startDateCheck">Start Date</label>
                    <span
                      class="label-type error event-error ml-2 d-none"
                      v-if="startDateError != ''"
                    >
                      Please Select Start Date.
                    </span>
                    <!-- <div
                      class="input-group date"
                      id="startDateCheck"
                      data-target-input="nearest"
                    >
                      <input
                        class="form-control"
                        placeholder="Start Date"
                        required="required"
                        id="startDateS"
                        type="date"
                        v-model="startDate"
                        v-on:change="checkdate"
                      />
                    </div> -->
                    <date-picker
                      :open="open"
                      @close="open = false"
                      :editable="false"
                      :clearable="false"
                      @click.native="handleClickStart"
                      v-model="startDate"
                      valueType="format"
                      format="YYYY-MM-DD"
                      placeholder="yyyy-mm-dd"
                      @change="checkdate"
                    >
                      <i slot="icon-calendar" class="cursor-pointer">
                        <img src="../../../assets/images/calendar-input.png" />
                      </i>
                    </date-picker>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0">
                  <div class="form-group">
                    <label for="startTimeCheck">Start Time</label>
                    <span
                      class="label-type error event-error ml-2"
                      v-if="timeError != ''"
                    >
                      Event Time is Invalid.
                    </span>
                    <div
                      class="input-group time"
                      id="startTimeCheck"
                      data-target-input="nearest"
                    >
                      <vue-timepicker
                        id="startTime"
                        class="time-input"
                        placeholder="10:00 AM"
                        format="hh:mm A"
                        required="required"
                        v-model="startTime"
                        v-on:change="checkTime"
                        close-on-complete
                        hide-clear-button
                      >
                        <template v-slot:icon>
                          <img src="../../../assets/images/clock-input.png" />
                        </template>
                      </vue-timepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-sm-12 col-md-6">
                  <div class="">
                    <label class="typo__label" for="ajaxsingle"
                      >Park Name</label
                    >
                    <multiselect
                      v-model="partName"
                      id="ajaxsingle"
                      label="park_name"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="parkNameOptions"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="getGamePark"
                      @input="checkParkField"
                      @select="dispatchAction"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag"
                          >&nbsp;<span> {{ option.park_name }}</span
                          ><span
                            class="custom__remove"
                            v-on:click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      >
                      <span slot="noResult"
                        >Oops! No Park found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No Park Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <span> {{ option.park_name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 pl-md-0 mt-2 mt-md-0">
                  <div class="">
                    <label class="typo__label" for="ajaxsingle"
                      >Field Name</label
                    >
                    <multiselect
                      v-model="parkField"
                      id="ajaxsingle"
                      label="field_name"
                      track-by="id"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="fieldNameOptions"
                      :searchable="true"
                      :loading="isLoadingField"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="getParkField"
                      @input="checkParkField"
                      @select="dispatchfieldAction"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag"
                          >&nbsp;<span> {{ option.field_name }}</span
                          ><span
                            class="custom__remove"
                            v-on:click="remove(option)"
                            >❌</span
                          ></span
                        ></template
                      ><span slot="noResult"
                        >Oops! No Field found. Consider changing the search
                        query.</span
                      >
                      <span slot="noOptions"> No Field Found </span>
                      <template slot="option" slot-scope="{ option }">
                        <div class="option__desc">
                          <span> {{ option.field_name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <textarea
                      type="text"
                      class="form-control pt-3 mt-2 mb-0 add-notes"
                      id="note"
                      rows="4"
                      v-model="note"
                      @keyup="errorNull"
                      placeholder="Comments"
                      maxlength="200"
                    ></textarea>
                  </div>
                </div>
                <a
                  class="d-none"
                  data-toggle="modal"
                  data-target="#ModalPARK"
                  ref="callPark"
                  @click="closeMain"
                >
                  Add New Park
                </a>
                <a
                  class="d-none"
                  data-toggle="modal"
                  data-target="#ModalField"
                  ref="callField"
                  @click="newField"
                >
                  Add New Field
                </a>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabledGame == false"
              type="button"
              class="btn btn-primary w-25"
              v-on:click="createGame(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabledGame == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="(true, isLoadingArray[0])"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewPark />
    <NewField />
  </div>
</template>
<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
// import $ from "jquery";
import Multiselect from "vue-multiselect";
import NewPark from "./AddPark";
import NewField from "./AddField";

export default {
  components: {
    Multiselect,
    NewPark,
    NewField,
    VueTimepicker,
  },
  name: "NewGame",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    singleEvent: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    newGameProps: {
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      isLoadingArray: [],
      selectedParkId: "",
      userName: localStorage.getItem("userName"),
      isDisabledGame: true,
      showLoaderGame: 0,
      gameName: "",
      opponentName: "",
      association: "",
      eventAlert: "",
      partName: "",
      parkField: "",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      startDate: "",
      startTime: "10:00 AM",
      note: "",
      startDateError: "",
      exactDateformat: "",
      timeError: "",
      eventAlertOptions: [],
      parkNameOptions: [],
      fieldNameOptions: [],
      isLoading: false,
      isLoadingField: false,
      tabsTo: 0,
      country: "United States",
      region: "",
      opponentCity: "",
      eventGame: {},
      newEventGame: { ...this.newGameProps },
    };
  },
  watch: {
    newGameProps(newProps) {
      this.newEventGame = { ...newProps };
    },
  },

  methods: {
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    checkParkField: function() {
      this.getParkField();
      this.errorNull();
    },
   

    errorNull: function() {
      if (
        this.gameName == "" ||
        this.exactStartDate == "" ||
        this.exactStartTime == "" ||
        this.opponentName == "" ||
        this.eventAlert == "" ||
        this.partName == "" ||
        this.opponentCity == "" ||
        this.country == "" ||
        this.region == ""
      ) {
        this.isDisabledGame = true;
      } else {
        this.isDisabledGame = false;
      }
    },

    // Check dates
    checkdate: function() {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", ";
      }

      this.checkTimeDate();
    },
    dispatchAction(actionName) {
      switch (actionName.park_name) {
        case "Add Park":
          var tabs = 0;
          this.$root.$emit("AddParkInitiate", tabs);
          var elem = this.$refs.callPark;
          elem.click();
          break;
        default:
          // Assume that the actionName contains the selected park's ID
          this.selectedParkId = actionName.id;
          break;
      }
    },
    dispatchfieldAction(actionName) {
      switch (actionName.field_name) {
        case "Add field":
          var tabs = 0;
          this.$root.$emit("AddFieldInitiate", {
            tabs,
            park_id: this.selectedParkId,
          });
          var elem = this.$refs.callField;
          elem.click();
          break;
      }
    },

    closeMain() {
      this.$root.$emit("AddPark");
      var elem = this.$refs.closeEventModal;
      elem.click();
    },
    newField() {
      this.$root.$emit("AddField");
      var elem = this.$refs.closeEventModal;
      elem.click();
    },
    checkTime: function() {
      // if (this.startTime != "") {
      //   var timeSplit = this.startTime.split(":"),
      //     hours,
      //     minutes,
      //     meridian;
      //   hours = timeSplit[0];
      //   minutes = timeSplit[1];
      //   if (hours > 12) {
      //     meridian = "PM";
      //     hours -= 12;
      //   } else if (hours < 12) {
      //     meridian = "AM";
      //     if (hours == 0) {
      //       hours = 12;
      //     }
      //   } else {
      //     meridian = "PM";
      //   }
      //   this.exactStartTime = hours + ":" + minutes + " " + meridian;
      //   this.timeError = "";
      //   this.isDisabledGame = false;
      // }

      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          hours -= 12;
        } else if (hours < 12) {
          if (hours == 0) {
            hours = 12;
          }
        }
        this.exactStartTime = hours + ":" + minutes;
        this.timeError = "";
        this.isDisabledGame = false;
      }

      this.checkTimeDate();
    },
    closePark() {
      this.tabsTo = 0;
      var start_time = document.getElementById("startTime");
      if (start_time.value != "") {
        start_time.value = "";
      }
    },
    checkTimeDate() {
      this.startDateError = this.exactStartDate != "" ? "" : "on";
      if (this.exactStartTime != "" && this.exactStartDate != "") {
        this.exactDateformat = this.exactStartDate + " " + this.exactStartTime;
      }
      this.errorNull();
    },

    getEventAlert() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getEventAlert`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.eventAlertOptions = response.data.data.alerts;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getGamePark(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getGamePark?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.parkNameOptions = response.data.data.parkName;
          this.isLoading = false;
        });
    },

    getParkField(searchQuery) {
      this.isLoadingField = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getParkField?park_id=${
            this.partName.id
          }&search=${searchQuery ? searchQuery : ""}`,
          this.details,
          {}
        )
        .then((response) => {
          
          this.fieldNameOptions = response.data.data.fields;
          this.isLoadingField = false;
        });
    },

    // Create Event
    async createGame(index) {
      this.showLoaderGame = 1;
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();

      formData.append("name", this.gameName ? this.gameName : "");
      if (this.newEventGame.event) {
      formData.append("team_id", this.newEventGame.event.team_id);
      formData.append("event_id", this.newEventGame.event.id);
      } else {
      formData.append("team_id", this.selectedRoomTeam.roomId);
      formData.append("event_id", this.singleEvent.id);
      }
      formData.append(
        "opponent_name",
        this.opponentName ? this.opponentName : ""
      );
      formData.append("start_date", this.startDate);
      const startTimeWithoutMeridian = this.startTime.replace(/\s*([AaPp][Mm])\s*/, "");
      formData.append("start_time", startTimeWithoutMeridian);
      formData.append("exactDateFormat", this.exactDateformat);
      formData.append("event_alert", this.eventAlert);
      formData.append("park_name", this.partName.id);
      if (this.parkField != "") {
        formData.append(
          "field_id",
          this.parkField.id ? this.parkField.id : null
        );
      }
      formData.append("comment", this.note ? this.note : "");
      formData.append("opponent_country", this.country);
      formData.append("opponent_state", this.region);
      formData.append("opponent_city", this.opponentCity);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$root.$emit("showGameList");
          this.$root.$emit("showEventPageGames");
          this.tabsTo = 0;
          var elem = this.$refs.closeEventModal;
          elem.click();
          this.showLoaderGame = 0;
          this.isDisabledGame = false;
          this.$set(this.isLoadingArray, index, false);
          this.$root.$emit("refreshEventGame", (this.newEventGame.event));
          this.newEventGame={};
        });
    },

    checkCurd() {
      if (this.tabsTo == 0) {
        this.isDisabledGame = true;
        this.showLoaderGame = 0;
        this.gameName = "";
        this.opponentName = "";
        this.association = "";
        this.eventAlert = "";
        this.partName = "";
        this.parkField = "";
        this.exactStartTime = "10:00 AM";
        this.exactStartDate = "";
        this.startDate = "";
        this.startTime = "10:00 AM";
        this.note = "";
        this.startDateError = "";
        this.exactDateformat = "";
        this.timeError = "";
        this.country = "United States";
        this.region = "";
        this.opponentCity = "";
        this.eventAlertOptions = [];
        this.parkNameOptions = [];
        this.fieldNameOptions = [];
        this.getEventAlert();
        this.getGamePark();
      } else {
        // this.partName = "";
        this.parkField = "";
      }
    },
  },

  mounted() {
    this.$root.$on("addedPark", (Tabs) => {
      this.tabsTo = Tabs;
      this.getGamePark();
      var elem = this.$refs.openModalXl;
      elem.click();
    });
    this.$root.$on("addedField", (Tabs) => {
      this.tabsTo = Tabs;
      this.getParkField();
      var elem = this.$refs.openModalXl;
      elem.click();
    });
    this.$root.$on("checkMain", () => {
      this.closeMain(); 
    });
  },
};
</script>
<style>
.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}

.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}

.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

a {
  font-size: 12px;
  font-weight: 500;
  margin: 0 7px;
  color: #188ef5 !important;
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
div.form-group .add-notes {
  font-size: 14px;
}
.form-group select {
  font-size: 14px !important;
  min-height: 45px !important;
  display: block !important;
  padding: 8px 40px 8px 8px !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  border-radius: 5px !important;
  color: #495055 !important;
}
</style>
