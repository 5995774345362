<template>
  <div class="invite-friend-wrapper d-none">
    <button class="btn invite-friends" @click="callModal()">
      <img src="../../assets/invite.png" alt="" class="mr-3" />
      Invite Friends
    </button>
  </div>
</template>
<script>
export default {
  name: "Invite-Friends",
  methods: {
    callModal: function() {
      this.$root.$emit("openInviteFriendModal");
      // document.querySelector("body").style.overflow = "hidden";
    },
  },
};
</script>
