<template>
  <div class="terms">
    <InviteFriends v-if="showInviteFriend == 1" />
    <div class="list">
      <ul>
        <li><router-link to="/privacy-policy">Privacy Policy</router-link><span>|</span></li>
        <li><router-link to="/cookie-policy">Cookie Policy</router-link><span>|</span></li>
        <li><router-link to="/refund-and-return-policy">Return & Refund Policy</router-link><span>|</span></li>
        <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
      </ul>
    </div>
    <div class="copyright">
      <p>&copy; {{ currYear }} Who I Follow Corp.</p>
    </div>
  </div>
</template>
<script>
import InviteFriends from "../Home/InviteFriends.vue";
export default {
  name: "Terms",
  data() {
    return {
      currYear: new Date().getFullYear(),
    };
  },
  components: {
    InviteFriends,
  },
  props: {
    showInviteFriend: {
      type: Number,
    },
  },
};
</script>