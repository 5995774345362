<template>
  <div>
    <div class="post-input">
      <div class="user-info d-flex align-items-center">
        <div class="user-img" v-if="user">
          <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
          <img
          @load="onImageLoad"
            v-if="user.picture == 'yes'"
            :src="Storage_URL + user.id + '/profile_picture.jpg?v=' + time"
            alt=""
          />
          <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="" />
        </div>
        <div
          v-if="user"
          class="user-input"
          data-toggle="modal"
          data-target="#postModal"
          @click="
            clearData();
            focusOnPostTextarea();
          "
        >
          What's on your mind, <span class="text-capitalize"> {{ user.name }} </span> ?
        </div>
        <!-- <input
          style="color: red"
          v-if="user"
          type="text"
          :placeholder="'What\'s on your mind, ' + user.name + '?'"
          data-toggle="modal"
          data-target="#exampleModal"
          readonly
          @click="clearData()"
        /> -->
      </div>
    </div>
    <form @keyup.enter="createPost(0)">
      <div
        class="modal fade bd-example-modal-lg"
        id="postModal"
        ref="modal-xl"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title">Create Post</h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeModalPost"
                @click="closePostModal()"
              >
                <img src="../../assets/close.png" />
              </button>
            </div>
            <div class="modal-body">
              <div v-if="user" class="user-info post mb-3">
                <div class="user-img">
                 <div class="users-notification" v-if="imgLoader == 1">
        <div class="lds-dual-ring-refresh"></div>
    </div>
                  <img
                    v-if="user.picture == 'yes'"
                    :src="
                      Storage_URL + user.id + '/profile_picture.jpg?v=' + time
                    "
                    alt=""
                  />
                  <img v-else src="../../assets/images/user-default-new.png" alt="" />
                </div>
                <div class="user-details">
                  <h6 v-if="user">{{ user.name }}</h6>
                </div>
              </div>
              <div v-if="user && user.exp_status == 1" class="specilist-toogle">
                <span class="float-left toogle" v-if="user.exp_status == 1"
                  >Pros</span
                >
                <toggle-button
                  class="float-left toogle"
                  v-model="details.expStatus"
                  :value="false"
                  :sync="true"
                  :width="34"
                />
              </div>
              <div class="post-content">
                <textarea
                  v-if="user"
                  ref="inputPostField"
                  type="text"
                  id="caption"
                  autofocus="autofocus"
                  @keyup="checkInput"
                  v-model="details.caption"
                  :placeholder="'What\'s on your mind, ' + user.name + '?'"
                  class="border-0 w-100"
                ></textarea>
                <div class="row no-gutters image-div">
                  <div
                    v-for="(data, index) in rawData"
                    :key="data"
                    class="image-input image-input-active d-flex"
                  >
                    <div class="image-preview">
                      <img class="img-responsive h-100" :src="data" />
                      <button
                        class="btn btn-xs remove-file"
                        @click="removeFile(index)"
                      >
                        <img
                          class="remove-Image"
                          src="../../assets/cross-icon.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>

                  <div
                    class="image-input image-input-tbd d-flex"
                    v-if="this.images.length < this.option.maxFileCount"
                  >
                    <div
                      class="image-preview dropzone d-flex justify-content-center align-items-center"
                      @click="openinput"
                    >
                      <img class="image-plus" src="../../assets/plus.png" />
                    </div>
                  </div>
                  <input
                    type="file"
                    class="d-none"
                    id="vue-file-upload-input"
                    @change="addImageNew"
                    accept="image/png, image/gif, image/jpeg"
                    multiple
                  />
                </div>
              </div>
            </div>
            <div>
              <p v-if="errorMessage != null" class="tag-line">
                {{ errorMessage }}
              </p>
              <div class="event-btns-wrapper justify-content-end p-3">
                <button
                  v-if="this.isDisabled == false"
                  type="button"
                  class="btn btn-primary w-25"
                  block
                  @click="createPost(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Post</span>
                </button>
                <button
                  v-else-if="this.isDisabled == true"
                  type="button"
                  class="btn btn-primary-disabled w-25"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Post</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "PostInput",
  data: function () {
    return {
      isDisabled: true,
      imgLoader: 1,
      errorMessage: null,
      isLoadingArray: [],
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      details: {
        caption: "",
        expStatus: false,
      },
      option: {
        maxFileCount: 100,
      },
      rawData: [],
      images: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    clearData: function (index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, false);
      this.details.caption = "";
      this.details.expStatus = false;
      this.images = [];
      this.rawData = [];
      document.getElementById("vue-file-upload-input").value = null;
      // document.querySelector("body").style.overflow = "hidden";
    },

    closePostModal: function () {
      // document.querySelector("body").style.overflow = "visible";
    },

    // ---------- Dont remove this function -------------
    // @drop="loaddropfile"
    // loaddropfile: function (e) {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   alert("ok");
    // },

    checkInput: function () {
      if (
        this.details.caption == null ||
        (this.details.caption == "" && this.images.length == 0)
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      // $('input[type="text"]').keyup(function() {
      //   if ($(this).val() != "") {
      //     this.disabledBtn = false;
      //   }
      // });
      $("#caption").on("keyup", stateHandle);

      function stateHandle(e) {
        if (
          $("#title").text() == e.target.value ||
          e.target.value.trim().length == 0
        ) {
          $("#post_btn").prop("disabled", true);
        } else {
          $("#post_btn").prop("disabled", false);
        }
      }
    },
    openinput: function () {
      document.getElementById("vue-file-upload-input").click();
    },

    addImageNew: function (event) {
      const self = this;
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        const file_ = files[i];
        this.images.push({
          target: event.target.files[i],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData.push(e.target.result);
        };
        reader.readAsDataURL(file_);
        this.errorMessage = null;
      }
      this.isDisabled = false;
    },

    removeFile: function (index) {
      this.images.splice(index, 1);
      this.rawData.splice(index, 1);
      document.getElementById("vue-file-upload-input").value = null;
      this.checkInput();
    },

    createPost: function (index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      this.errorMessage = null;
      var formData = new FormData();
      var expertStatus = this.details.expStatus == true ? 1 : 0;
      formData.append("caption", this.details.caption);
      formData.append("exp_status", expertStatus);
      this.images.forEach((value, index) => {
        formData.append("images[" + index + "]", value.target);
      });

      return axios
        .post(process.env.VUE_APP_API_URL + "post/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            var elem = this.$refs.closeModalPost;
            elem.click();
            this.$root.$emit("PostView");
            this.$root.$emit("Specialists");
            this.details.caption = "";
            this.images = [];
            this.details.expStatus = false;
            this.rawData = [];
          } else {
            this.errorMessage = response.data.message;
          }
          this.$set(this.isLoadingArray, index, false);
        });
    },
    focusOnPostTextarea() {
      setTimeout(() => {
        $("#caption").focus();
        this.$refs.inputPostField.focus();
      }, 1000);
      $("#caption").keydown(function (e) {
        return e.which !== 13;
      });
    },
    run() {
      console.log(this.details.caption);
    },
  },
  mounted() {
    this.focusOnPostTextarea();
  },
};
</script>
<style scoped>
.cursor {
  position: relative;
}
.cursor i {
  position: absolute;
  width: 1px;
  height: 38%;
  background-color: rgb(10, 9, 9);
  left: 0;
  top: 2%;
  animation-name: blink;
  animation-duration: 975ms;
  animation-iteration-count: infinite;
}

.cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
