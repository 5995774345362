<template>
  <div>
    <div v-if="showInvitesShimmer == 0 && invitedMembers.length != 0">
      <div class="team-member">
        <h6>Invited Teammates</h6>
        <span class="nav-counter-team">{{ invitedMembers.length }}</span>
      </div>
      <div class="team-members">
        <div class="team-info" v-for="user in invitedMembers" :key="user.id">
          <div class="team-details">
            <div class="team-image">
              <div class="users-notification" v-if="imgLoader == 1">
                <!-- <div class="lds-dual-ring-refresh"></div> -->
                <img src="../../../../assets/loader.gif" alt="" />
              </div>
              <img
                @load="onImageLoad"
                onerror="this.src='https://api.whoifollow.tech/user-default2.png'"
                alt=""
              />
            </div>
            <div class="team-name-role">
              <h6 class="text-lowercase">{{ user.target }}</h6>
              <p v-if="user.level == '1'">
                <span v-if="user.fname || user.lname" class="mr-0"
                  >{{ user.fname }} {{ user.lname }}
                </span>
                - Teammate
              </p>
              <p v-if="user.level == '2'"><span v-if="user.fname || user.lname" class="mr-0"
                  >{{ user.fname }} {{ user.lname }}
                </span> - Fan</p>
            </div>
          </div>
          <span
            class="invited-function"
            @click="fetchData(user)"
            v-if="currentIsAdmin == '1'"
          >
            <img src="../../../../assets/images/edit.png" alt="" />
          </span>
        </div>
        <InvitesTeamOption v-if="currentIsAdmin == '1'" />
      </div>
    </div>

    <div class="row no-gutters" v-if="showInvitesShimmer == 1">
      <div class="col-12 p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="true" />
        </content-placeholders>
      </div>
    </div>

    <div
      class="no-chat-text border-top pt-3"
      v-if="showInvitesShimmer == 0 && invitedMembers.length == 0"
    >
      <div class="team-member">
        <h6>Invited Teammates</h6>
        <span class="nav-counter-team">{{ invitedMembers.length }}</span>
      </div>
      <p>No invites found.</p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import InvitesTeamOption from "../ChatSettingComponents/InvitesTeamOption.vue";
export default {
  name: "TeamInviteList",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  components: {
    InvitesTeamOption,
  },
  data() {
    return {
      imgLoader: 1,
      invitedMembers: [],
      showInvitesShimmer: 0,
    };
  },

  mounted() {
    this.getInvitesList();
    this.$root.$on("HitInviteTeamList", () => {
      this.getInvitesList();
    });
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    fetchData(item) {
      this.$root.$emit("fetchInviteTeamRow", item);
    },
    async getInvitesList() {
      this.showInvitesShimmer = 1;
      var formData = new FormData();
      formData.append("team_id", this.selectedRoomTeam.roomId);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "invite/inviteTeamListing",
          formData
        )
        .then((response) => {
          this.invitedMembers = response.data.data;
          this.showInvitesShimmer = 0;
        });
    },
  },
};
</script>
<style>
.team-members-functions {
  cursor: pointer;
  position: relative;
}
.loaderSign {
  width: 45px !important;
  margin-top: 0px !important;
}
.team-members-functions img {
  cursor: pointer;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.team-invite-links {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(229, 226, 226);
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
  color: #188ef5;
}

.team-invite-links h6 {
  color: #188ef5;
  font-weight: 600;
  font-size: 15px;
}

.invited-info .team-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.invited-info .team-image {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f6;
}

.team-image .user-default {
  width: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.team-image img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.team-details .team-name-role h6 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.team-details .team-name-role p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #869cb1;
}

.team-detail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.invited-function img {
  width: 20px;
  cursor: pointer;
}
</style>
