<template>
  <div>
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        mb-2
        mt-3
        p-1
        position-relative
      "
    >
      <h6 class="s-heading m-0 text-uppercase">Friend Requests</h6>
      <div class="req-counter" v-if="items > 9">9+</div>
      <div class="req-counter" v-if="items <= 9">
        {{ items }}
      </div>
    </div>
    <div v-if="friendsRequestsList != []">
      <div class="friends-requests" v-if="showLoader == 0">
        <div
          class="user-info py-1"
          v-for="list in friendsRequestsList.slice(0, 3)"
          :key="list.id"
        >
          <div class="user-img">
            <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../assets/loader.gif" alt="">
    </div>
            <router-link
              :to="/profile/ + list.user_link"
              exact
            >
              <img
              @load="onImageLoad"
                v-if="list.picture == 'yes'"
                :src="API_URL + list.user_id + '/profile_picture.jpg?v=' + time"
                alt=""
              />
              <img
              @load="onImageLoad"
                v-else
                src="../../assets/images/user-default-new.png"
                alt=""
              
              />
            </router-link>
          </div>
          <router-link
            :to="/profile/ + list.user_link"
            exact
            style="color: black"
          >
            <div class="user-details">
              <h6>{{ list.name }}</h6>
            </div>
          </router-link>
          <div class="delete d-flex align-items-center ml-lg-auto">
            <div
              class="delete-icon"
              @click="cancelRequest(list.user_id)"
              :id="'cancel' + list.user_id"
            >
              <img src="../../assets/images/trash.png" alt="" />
            </div>
            <div
              class="tick-icon"
              @click="acceptRequest(list.user_id)"
              :id="'accept' + list.user_id"
            >
              <img src="../../assets/images/profile-tick.png" alt="" />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
    <div v-if="friendsRequestsList == []">
      <InviteFriends class="friends-requests" />
    </div>
    <div class="friends-requests" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
      <br />
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import InviteFriends from "../Home/InviteFriends";
import $ from "jquery";
export default {
  name: "FriendRequests",
  props: {
    items: {
      type: Number,
    },
  },
  data() {
    return {
      friendsRequestsList: [],
      showLoader: 0,
      counter: 9,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      imgLoader: 1,
    };
  },
  components: {
    InviteFriends,
  },

  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async friendRequestsListing() {
      this.showLoader = 1;
      let friendReq = await axios.get(`friends/friendRequestFirstThree`);

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsList = friendReq.data.data.listing;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
    async cancelRequest(friend_id) {
      $("#cancel" + friend_id).hide();
      $("#accept" + friend_id).hide();
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/cancelRequest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$root.$emit("FriendRequestCountCheck");
            this.friendRequestsListing();
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    async acceptRequest(friend_id) {
      $("#cancel" + friend_id).hide();
      $("#accept" + friend_id).hide();
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/acceptRequest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$root.$emit("FriendRequestCountCheck");
            this.friendRequestsListing();
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
  },

  mounted() {
    this.friendRequestsListing(1);
    this.$root.$on("FriendRequestshowLoaderOff", () => {
      this.showLoader = 0;
    });
  },
};
</script>
